import React from 'react';
import './style.css';
import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { styled, alpha, Box } from '@mui/system';
import { Slider as BaseSlider, sliderClasses  } from '@mui/base/Slider'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import CustomCheckbox from '../../checkboxes/customCheckbox';
import CustomInput from '../../inputs/customInput';
import CancelButton from '../../buttons/cancelButton';
import CustomButton from '../../buttons/customButton';
import CustomPassword from '../../inputs/customPassword';
import { updateApplication, createApplication } from '../../../api/apps';
import { codeTypes, MAX_CODE_LENGTH, MAX_APP_NAME_LENGTH, MESSAGE_TEMPLATE, USE_SMS } from '../../../utils/constants';
import { setDataAlert } from '../../../redux/actions/alert';

const EditAppModal = (props) => {
	const { open, setClose, app } = props;
	const dispatch = useDispatch();
	const alertState = useSelector((state) => state.alert);
	const getInitType = () => {
		return app ? app.code_type : 'null';
	}
	const [selectOTP, setSelectOTP] = React.useState(
		app ? (app.auth_type === 0 || app.auth_type === 1) : true
	);
	const [selectOAUTH2, setSelectOAUTH2] = React.useState(
		app ? (app.auth_type === 0 || app.auth_type === 2) : false
	);
	const [type, setType] = React.useState(getInitType);
	const [appName, setAppName] = React.useState(app ? app.name : '');
	const [secretKey, setSecretKey] = React.useState(app ? app.client_secret : uuidv4().replace(/-/g, ''));
	const [authCallback, setAuthCallback] = React.useState(app ? app.redirect_uri : '');
	const [validName, setValidName] = React.useState(
		app?.name ? 'success' : 'root'
	);
	const [validSecretKey, setValidSecretKey] = React.useState('success');
	const [validCallback, setValidCallback] = React.useState(
		app?.redirect_uri ? 'success' : 'root'
	);
	const [errorNameMessage, setErrorNameMessage] = React.useState('');
	const [errorKeyMessage, setErrorKeyMessage] = React.useState('');
	const [errorCallbackMessage, setErrorCallbackMessage] = React.useState('');
	const [codeLength, setCodeLength] = React.useState(app ? app.code_length : 4);
	const [login, setLogin] = React.useState(app?.sip_username ?? '');
	const [password, setPassword] = React.useState(app?.sip_secret ?? '');
	const [server, setServer] = React.useState(app?.sip_server ?? '');
	const [smsLogin, setSmsLogin] = React.useState(app?.sms_login ?? '');
	const [smsPassword, setSmsPassword] = React.useState(app?.sms_password ?? '');
	const [validLogin, setValidLogin] = React.useState(app?.sip_username ? 'success' : 'root');
	const [validPassword, setValidPassword] = React.useState(app?.sip_secret ? 'success' : 'root');
	const [validServer, setValidServer] = React.useState(app?.sip_server ? 'success' : 'root');
	const [validSmsLogin, setValidSmsLogin] = React.useState(app?.sms_login ? 'success' : 'root');
	const [validSmsPassword, setValidSmsPassword] = React.useState(app?.sms_password ? 'success' : 'root');
	const [errorLoginMessage, setErrorLoginMessage] = React.useState('');
	const [errorPasswordMessage, setErrorPasswordMessage] = React.useState('');
	const [errorServerMessage, setErrorServerMessage] = React.useState('');
	const [errorSmsLoginMessage, setErrorSmsLoginMessage] = React.useState('');
	const [errorSmsPasswordMessage, setErrorSmsPasswordMessage] = React.useState('');
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const mainColor = '#2186F0';

	const closeModal = () => {
		setClose(false);
	};

	const handleSelectOTP = (value) => {
		if (!value && !selectOAUTH2) return;
		else setSelectOTP(value);
	};

	const handleSelectOAUTH2 = (value) => {
		if (!value && !selectOTP) return;
		else setSelectOAUTH2(value);
	};

	const handleSelectType = (value) => {
		setType(value);
	};

	const changeAppName = (value) => {
		setAppName(value);
		if (value.length && value.length <= MAX_APP_NAME_LENGTH) setValidName('success');
		else setValidName('error');
	};

	const changeSecretKey = (value) => {
		setSecretKey(value);
		if (value.length) setValidSecretKey('success');
		else setValidSecretKey('error');
	};

	const changeCallback = (value) => {
		setAuthCallback(value);
		if (value.length) setValidCallback('success');
		else setValidCallback('error');
	};

	const changeLogin = (value) => {
		setLogin(value);
		if (value.length) setValidLogin('success');
		else setValidLogin('error');
	};

	const changePassword = (value) => {
		setPassword(value);
		if (value.length) setValidPassword('success');
		else setValidPassword('error');
	};

	const changeServer = (value) => {
		setServer(value);
		if (value.length) setValidServer('success');
		else setValidServer('error');
	};

	const changeSmsLogin = (value) => {
		setSmsLogin(value);
		if (value.length) setValidSmsLogin('success');
		else setValidSmsLogin('error');
	};

	const changeSmsPassword = (value) => {
		setSmsPassword(value);
		if (value.length) setValidSmsPassword('success');
		else setValidSmsPassword('error');
	};

	const editApplication = async () => {
		if (
			validName === 'success' &&
			validSecretKey === 'success' &&
			((selectOAUTH2 && validCallback === 'success') || !selectOAUTH2) &&
			(
				(type === codeTypes.number) ||
				(type === codeTypes.sms && validSmsLogin === 'success' && validSmsPassword === 'success') ||
				(type === codeTypes.voice && validLogin === 'success' && validSecretKey === 'success' && validServer === 'success')
			)
		) {
			const body = {
				client_secret: secretKey,
				redirect_uri: authCallback,
				name: appName,
				auth_type: selectOTP && selectOAUTH2 ? 0 : selectOTP ? 1 : 2,
				code_type: type,
				code_length: codeLength,
				sip_server: server,
				sip_username: login,
				sip_secret: password,
				sms_login: smsLogin,
				sms_password: smsPassword
			};
			if (app) await updateApplication({ body, app });
			else await createApplication({ body });
			closeModal();
		} else if (validName !== 'success' && !appName.length) {
			setValidName('error');
			setErrorNameMessage('Название приложения не введено');
		} else if (validName !== 'success' && appName.length > MAX_APP_NAME_LENGTH) {
			setValidName('error');
			setErrorNameMessage(`Название приложения содержит более ${MAX_APP_NAME_LENGTH} символов`);
		} else if (validSecretKey !== 'success') {
			setValidSecretKey('error');
			setErrorKeyMessage('Секретный ключ не введен');
		} else if (selectOAUTH2 && validCallback !== 'success') {
			setValidCallback('error');
			setErrorCallbackMessage('Адрес авторизации не введен');
		} else if (type === 'null' && !alertState.data.show) {
			dispatch(
				setDataAlert({
					show: true,
					message: 'Способ доставки кода не выбран',
				})
			);
		} else if (type === codeTypes.voice && validLogin !== 'success') {
			setValidLogin('error');
			setErrorLoginMessage('Логин не введен');
		} else if (type === codeTypes.voice && validPassword !== 'success') {
			setValidPassword('error');
			setErrorPasswordMessage('Пароль не введен');
		} else if (type === codeTypes.voice && validServer !== 'success') {
			setValidServer('error');
			setErrorServerMessage('Сервер не введен');
		} else if (type === codeTypes.sms && validSmsLogin !== 'success') {
			setValidSmsLogin('error');
			setErrorSmsLoginMessage('Логин не введен');
		} else if (type === codeTypes.sms && validSmsPassword !== 'success') {
			setValidSmsPassword('error');
			setErrorSmsPasswordMessage('Пароль не введен');
		}
	};

	function SliderValueLabel({ children }) {
		return <span className="valueLabel">{children}</span>;
	}

	function valuetext(value) {
		return `${value}`;
	}

	const Slider = styled(BaseSlider)(
		() => `
  color: ${mainColor};
  height: 6px;
  width: 100%;
  padding: 16px 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &.${sliderClasses.disabled} {
    pointer-events: none;
    cursor: default;
    color: ${mainColor};
    opacity: 0.4;
  }

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    background-color: currentColor;
    opacity: 0.3;
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 6px;
    background-color: currentColor;
  }

  & .${sliderClasses.thumb} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -6px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    background-color: ${mainColor};
    transition-property: box-shadow, transform;
    transition-timing-function: ease;
    transition-duration: 120ms;
    transform-origin: center;

    &:hover {
      box-shadow: 0 0 0 6px ${alpha(
			mainColor,
			0.3,
		)};
    }

    &.${sliderClasses.focusVisible} {
      box-shadow: 0 0 0 8px ${alpha(
			mainColor,
			0.5,
		)};
      outline: none;
    }

    &.${sliderClasses.active} {
      box-shadow: 0 0 0 8px ${alpha(
			mainColor,
			0.5,
		)};
      outline: none;
      transform: scale(1.2);
    }
  }

  & .${sliderClasses.mark} {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 99%;
    background-color: ${mainColor};
    transform: translateX(-50%);
  }

  & .${sliderClasses.markActive} {
    background-color: ${mainColor};
  }

  & .valueLabel {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    top: 2em;
    text-align: center;
    align-self: center;
  }
`,
	);

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={closeModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div className="add_app_modal">
				<h2>{app ? 'Редактировать' : 'Создать'} приложение</h2>
				<div className="add_app_modal__selectors">
					<CustomCheckbox
						label="OTP"
						checked={selectOTP}
						changeSelect={handleSelectOTP}
					/>
					<CustomCheckbox
						label="OAUTH 2"
						checked={selectOAUTH2}
						changeSelect={handleSelectOAUTH2}
					/>
				</div>
				<div className="add_app_modal__row">
					<span className="add_app_modal__row__label">
						Название приложения <span style={{ color: 'red' }}>*</span>
					</span>
					<CustomInput
						mode={validName}
						value={appName}
						changeInput={changeAppName}
						errorMessage={errorNameMessage}
					/>
				</div>
				<div className="add_app_modal__row">
					<span className="add_app_modal__row__label">
						Секретный ключ <span style={{ color: 'red' }}>*</span>
					</span>
					<CustomPassword
						mode={validSecretKey}
						value={secretKey}
						changeInput={changeSecretKey}
						errorMessage={errorKeyMessage}
					/>
				</div>
				<div
					className={
						selectOAUTH2
							? 'add_app_modal__row'
							: 'add_app_modal__row hidden_block'
					}
				>
					<span className="add_app_modal__row__label">
						Адрес авторизации в приложении (callback){' '}
						<span style={{ color: 'red' }}>*</span>
					</span>
					<CustomInput
						mode={validCallback}
						value={authCallback}
						changeInput={changeCallback}
						placeholder="https://example.com/auth"
						errorMessage={errorCallbackMessage}
					/>
				</div>
				{
					type === codeTypes.sms ?
						<div className="add_app_modal__sip">
							<h3>SMSC аккаунт</h3>
							<div className="add_app_modal__row">
								<span className="add_app_modal__row__label">
									Логин{' '}
									<span style={{ color: 'red' }}>*</span>
								</span>
								<CustomInput
									mode={validSmsLogin}
									value={smsLogin}
									changeInput={changeSmsLogin}
									placeholder=""
									errorMessage={errorSmsLoginMessage}
								/>
							</div>
							<div className="add_app_modal__row">
								<span className="add_app_modal__row__label">
									Пароль{' '}
									<span style={{ color: 'red' }}>*</span>
								</span>
								<CustomPassword
									mode={validSmsPassword}
									value={smsPassword}
									changeInput={changeSmsPassword}
									errorMessage={errorSmsPasswordMessage}
								/>
							</div>
							<div className="add_app_modal__row">
								<span className="add_app_modal__row__label">
									Текст сообщения (Пример)
								</span>
								<span>{MESSAGE_TEMPLATE.replace('{app}', appName).replace('{code}', '1234567890'.slice(0,codeLength))}</span>
							</div>
						</div>: null
				}
				{
					type === codeTypes.voice ?
						<div className="add_app_modal__sip">
							<h3>SIP аккаунт</h3>
							<div className="add_app_modal__row">
								<span className="add_app_modal__row__label">
									Логин{' '}
									<span style={{ color: 'red' }}>*</span>
								</span>
								<CustomInput
									mode={validLogin}
									value={login}
									changeInput={changeLogin}
									placeholder=""
									errorMessage={errorLoginMessage}
								/>
							</div>
							<div className="add_app_modal__row">
								<span className="add_app_modal__row__label">
									Пароль{' '}
									<span style={{ color: 'red' }}>*</span>
								</span>
								<CustomPassword
									mode={validPassword}
									value={password}
									changeInput={changePassword}
									errorMessage={errorPasswordMessage}
								/>
							</div>
							<div className="add_app_modal__row">
							<span className="add_app_modal__row__label">
								Сервер{' '}
								<span style={{ color: 'red' }}>*</span>
							</span>
								<CustomInput
									mode={validServer}
									value={server}
									changeInput={changeServer}
									placeholder=""
									errorMessage={errorServerMessage}
								/>
							</div>
						</div> : null
				}
				<div className="add_app_modal__row">
					<span className="add_app_modal__row__label">Способ доставки кода</span>
					<div className="add_app_modal__transports">
						<CustomCheckbox
							label="ЗВОНОК"
							checked={type === codeTypes.number}
							changeSelect={() => handleSelectType(codeTypes.number)}
						/>
						{USE_SMS ? <CustomCheckbox
							label='SMS'
							checked={type === codeTypes.sms}
							changeSelect={() => handleSelectType(codeTypes.sms)}
						/> : null}
						<CustomCheckbox
							label="ГОЛОС"
							checked={type === codeTypes.voice}
							changeSelect={() => handleSelectType(codeTypes.voice)}
						/>
					</div>
				</div>
				<div className="add_app_modal__row">
					<span className="add_app_modal__row__label">Количество символов кодовой комбинации</span>
					<Box sx={{width: 200}}>
						<Slider
							orientation="horizontal"
							value={codeLength}
							max={MAX_CODE_LENGTH}
							min={4}
							marks
							step={1}
							slots={{ valueLabel: SliderValueLabel }}
							getAriaValueText={valuetext}
							onChange={(value) => {
								setCodeLength(value.target.value);
							}}
						/>
					</Box>
				</div>
				<div className="add_app_modal__row__buttons">
					<div className="edit_app_modal__row__buttons_right">
						<CancelButton text={'Отмена'} onClick={closeModal} />
						<CustomButton text={app ? 'Сохранить' : 'Создать'} onClick={editApplication}/>
					</div>
				</div>
				<CloseIcon className="close_button" onClick={closeModal} />
			</div>
		</Dialog>
	);
};

export default EditAppModal;
