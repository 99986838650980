import React from 'react';
import './style.css';
import Popover from '@material-ui/core/Popover';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios';
import { URL_SERVER } from '../../../utils/constants';
import { setDataAdmins } from '../../../redux/actions/admins';
import { setSession } from '../../../redux/actions/session';
import { useSelector, useDispatch } from 'react-redux';
import AlertMessage from '../../alertMessage';

const useStyles = makeStyles({
	popover: {
		'& .MuiPopover-paper': {
			padding: 20,
			display: 'flex',
			flexDirection: 'column',
			alignAtems: 'center',
			'& .MuiButtonBase-root': {
				marginTop: 10,
			},
			'& .MuiFormControl-root': {
				marginBottom: 10,
			},
		},
	},
});

const AddAdmin = () => {
	const dispatch = useDispatch();
	const admins = useSelector((state) => state.admins);
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [phone, setPhone] = React.useState('+7');
	const [alert, setAlert] = React.useState({
		show: false,
		severity: null,
		message: null,
	});
	let alertMessage;

	const onCloseAlertMessage = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert({
			...alert,
			show: false,
		});
		if (alertMessage && alertMessage.current)
			alertMessage.current.style.display = 'none';
	};

	let phoneRef;

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const changePhone = (e) => {
		if (e.target.value.length < 13 && /[+]\d+$/.test(e.target.value)) {
			setPhone(e.target.value);
		} else if (!e.target.value.length) {
			setPhone('');
		}
	};

	const create = (e) => {
		setAnchorEl(e.target);
	};

	const save = () => {
		(async () => {
			const body = {
				phone,
				admin: true,
			};
			if (!phone) phoneRef.focus();
			else {
				await axios
					.get(`${URL_SERVER}/api/admin/v1/users`, { withCredentials: true })
					.then(async (res) => {
						let users = res.data;
						users = users.filter((user) => user.phone === phone);
						console.log(users);
						if (users.length && users[0].admin) {
							setAnchorEl(null);
							setAlert({
								show: true,
								severity: 'warning',
								message: 'Пользователь является администратором',
							});
						} else if (users.length && users.length === 1) {
							await axios
								.patch(
									`${URL_SERVER}/api/admin/v1/users/${users[0].id}`,
									{ admin: true },
									{
										withCredentials: true,
									}
								)
								.then((res) => {
									const adminsToState = [...admins.data];
									adminsToState.push(res.data);
									dispatch(setDataAdmins(adminsToState));
									setAnchorEl(null);
									setAlert({
										show: true,
										severity: 'success',
										message: 'Пользователь добавлен в список администраторов',
									});
								})
								.catch((err) => {
									console.log(err.message);
									if (err.message.includes('401')) {
										dispatch(setSession(false));
									}
								});
						} else if (!users.length) {
							await axios
								.post(`${URL_SERVER}/api/admin/v1/users`, body, {
									withCredentials: true,
								})
								.then((res) => {
									const adminsToState = [...admins.data];
									adminsToState.push(res.data);
									dispatch(setDataAdmins(adminsToState));
									setAnchorEl(null);
									setAlert({
										show: true,
										severity: 'success',
										message: 'Пользователь добавлен в список администраторов',
									});
								})
								.catch((err) => {
									console.log(err.message);
									if (err.message.includes('401')) {
										dispatch(setSession(false));
									}
								});
						}
					})
					.catch((err) => {
						if (err.message.includes('401')) {
							console.log(err.message);
							dispatch(setSession(false));
						}
					});
			}
		})();
	};

	return (
		<div className="add_connection">
			<Button variant="outlined" color="primary" onClick={create}>
				Добавить администратора
			</Button>
			<Popover
				className={classes.popover}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<FormControl variant="outlined">
					<TextField
						id="outlined-adornment-phone"
						label="Телефон"
						onChange={changePhone}
						inputRef={(n) => (phoneRef = n)}
						value={phone}
					/>
				</FormControl>
				<Button variant="outlined" color="primary" onClick={save}>
					Добавить
				</Button>
			</Popover>
			<AlertMessage
				open={alert.show}
				close={onCloseAlertMessage}
				severity={alert.severity}
				message={alert.message}
				refSnackBar={alertMessage}
			/>
		</div>
	);
};

export default AddAdmin;
