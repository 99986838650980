import React from 'react';
import './style.css';
import CachedIcon from '@material-ui/icons/Cached';
import { makeStyles } from '@material-ui/core/styles';
import IconTooltip from '../../tooltips/iconTooltip/index';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiSvgIcon-colorPrimary': {
			color: '#8387A0',
			'&:hover': {
				cursor: 'pointer',
				color: '#2186F0',
			},
		},
		'& .MuiSvgIcon-colorSecondary': {
			color: '#2186F0',
			'&:hover': {
				cursor: 'pointer',
				color: '#2186F0',
			},
		},
		'& .icon_rotate': {
			animation: 'rotation 1s infinite linear',
		},
	},
}));

const RefreshIcon = (props) => {
	const { onClick, loading } = props;
	const classes = useStyles();

	const rotateIcon = () => {
		onClick();
	};

	return (
		<div className={classes.root} onClick={rotateIcon}>
			<IconTooltip title="Обновить">
				<CachedIcon
					color={loading ? 'secondary' : 'primary'}
					className={loading ? 'icon_rotate' : ''}
					fontSize="small"
				/>
			</IconTooltip>
		</div>
	);
};

export default RefreshIcon;
