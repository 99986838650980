import React from 'react';
import './style.css';

const NavMenuButton = (props) => {
	const { text, onClick, page, icon} = props;
	return (
		<button
			className={
				page === text
					? 'nav_menu_button nav_menu_button__active'
					: 'nav_menu_button'
			}
			onClick={() => onClick(text)}
		>
			<div className="align">
				{icon}
				<span>{text}</span>
			</div>
		</button>
	);
};

export default NavMenuButton;
