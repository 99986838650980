import React from 'react';
import './style.css';
import { useSelector } from 'react-redux';
import AppsPage from '../appsPage';
import LogPage from '../logPage';
import LicensePage from '../licensePage';

const MainPage = () => {
	const page = useSelector((state) => state.page);

	return (
		<div className="main_page__wrapper">
			<div className="main_page">
				{{
					['Приложения']: <AppsPage />,
					['Журнал']: <LogPage />,
					['Лицензии']: <LicensePage />
				}[page.data] || null}
			</div>
		</div>
	);
};

export default MainPage;
