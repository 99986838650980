import React from 'react';
import './style.css';
import Modal from '@mui/material/Modal';
import CancelButton from '../../buttons/cancelButton';
import CustomButton from '../../buttons/customButton';
import CloseIcon from '@mui/icons-material/Close';
import { deleteApplication } from '../../../api/apps';

const DeleteAppModal = (props) => {
	const { open, setClose, app } = props;

	const closeModal = () => {
		setClose(false);
	};

	const onDeleteApp = async () => {
		await deleteApplication({ app });
		closeModal();
	};

	return (
		<Modal
			open={open}
			onClose={closeModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div className="delete_app_modal">
				<h2>Удаление приложения</h2>
				<span className="delete_app_modal__question">
					Вы действительно хотите удалить приложение?
				</span>
				<div className="delete_app_modal__row__buttons">
					<div className="delete_app_modal__row__buttons_right">
						<CancelButton text={'Отмена'} onClick={closeModal} />
						<CustomButton text="Удалить" onClick={onDeleteApp} />
					</div>
				</div>
				<CloseIcon className="close_button" onClick={closeModal} />
			</div>
		</Modal>
	);
};

export default DeleteAppModal;
