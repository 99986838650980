import React from 'react';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';

const CustomButtonHeader = (props) => {
	const { onClick, text, disabled, disabledActivateButton } = props;

	return (
		<div className={
			disabledActivateButton
				? 'activate_balance activate_balance_disabled'
				: 'activate_balance'
		} onClick={onClick}>
			{disabled ? (
				<LoadingButton
					size="large"
					loading={disabled}
					loadingPosition="center"
					loadingIndicator={
						<CircularProgress size={22} thickness={3} disableShrink={true} />
					}
					disabled={disabled}
				/>
			) : (
				text
			)}
		</div>
	);
};

export default CustomButtonHeader;
