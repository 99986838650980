import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { codeTypes, URL_SERVER } from '../../../utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { setDataSipTrunks, setDataSmsTrunks } from '../../../redux/actions/siptrunks';
import { setSession } from '../../../redux/actions/session';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDeleteConnection = ({ open, setOpen, state, type }) => {
	const connections = useSelector((state) => state.siptrunks);
	const dispatch = useDispatch();

	const handleClose = () => {
		setOpen(false);
	};

	const deleteConnection = () => {
		(async () => {
			await axios
				.delete(`${URL_SERVER}/api/v1/${type === codeTypes.number ? 'siptrunks' : 'smstrunks'}/${state.id}`, {
					withCredentials: true,
				})
				.then((res) => {
					const connectionsToState = type === codeTypes.number ? [...connections.dataSip] : [...connections.dataSms];
					if (type === codeTypes.number)	dispatch(setDataSipTrunks(connectionsToState.filter((app) => app.id !== state.id)));
					else dispatch(setDataSmsTrunks(connectionsToState.filter((app) => app.id !== state.id)));
					setOpen(false);
				})
				.catch((err) => {
					console.log(err.message);
					if (err.message.includes('401')) {
						dispatch(setSession(false));
					}
				});
		})();
	};

	return (
		<div className="confirm_dialog">
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				maxWidth="md"
			>
				<DialogTitle>
					Вы действительно хотите удалить подключение {state.name}?
				</DialogTitle>
				<DialogActions>
					<Button onClick={handleClose}>Отмена</Button>
					<Button onClick={deleteConnection}>Удалить</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ConfirmDeleteConnection;
