import React from 'react';

export default function CrmTools() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 192 192"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M73.5 165.165V186H118.5V165.165C157.318 155.174 186 119.936 186 78L141 78C141 102.853 120.853 123 96 123C71.1472 123 51 102.853 51 78L6 78C6 119.936 34.6824 155.174 73.5 165.165Z"
				fill="#2196F3" />
			<path
				d="M132 42C132 61.8823 115.882 78 96 78C76.1178 78 60 61.8823 60 42C60 22.1178 76.1178 6 96 6C115.882 6 132 22.1178 132 42Z"
				fill="#98CFFA" />
		</svg>
	);
}
