import React from 'react';
import './style.css';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import axios from 'axios';
import { URL_SERVER } from '../../../utils/constants';
import { setSession } from '../../../redux/actions/session';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
	popover: {
		'& .MuiPopover-paper': {
			padding: 20,
			display: 'flex',
			flexDirection: 'column',
			alignAtems: 'center',
			'& .MuiButtonBase-root': {
				marginTop: 10,
			},
			'& .MuiFormControl-root': {
				marginBottom: 10,
			},
		},
	},
});

const BalanceButton = (props) => {
	const { phone, password, server, type } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [message, setMessage] = React.useState(null);
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const getBalance = (e) => {
		(async () => {
			const body = {
				phone,
				password,
				server,
				type
			};
			await axios
				.post(`${URL_SERVER}/admin/balance`, body, {
					withCredentials: true,
				})
				.then((res) => {
					setMessage(`Баланс: ${res.data.balance}`);
					setAnchorEl(e.target);
				})
				.catch((err) => {
					console.log(err.message);
					if (err.message.includes('401')) {
						dispatch(setSession(false));
					} else if (err.message.includes('400')) {
						setMessage(err.response.data.message);
						setAnchorEl(e.target);
					}
				});
		})();
	};

	return (
		<div>
			<div className="balance_button" onClick={getBalance}>
				<AccountBalanceWalletIcon color="primary" />
			</div>
			<Popover
				className={classes.popover}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{message}
			</Popover>
		</div>
	);
};

export default BalanceButton;
