import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import LicenseTable from '../tables/licenseTable';
import { MARKET_LINK_BUY, codeTypes } from '../../utils/constants';
import { setBalanceModal, setBitrixModal } from '../../redux/actions/modals';
import { setDataAlert } from '../../redux/actions/alert';
import { setShowBalanceAlert } from '../../redux/actions/session';

const LicensePage = () => {
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const alertState = useSelector((state) => state.alertState);
	const session = useSelector((state) => state.session);

	React.useEffect(() => {
		if (
			Object.keys(profile?.data).length &&
			!profile?.data?.balance &&
			!alertState?.data?.show &&
			!session?.showBalanceAlert
		) {
			dispatch(
				setDataAlert({
					show: true,
					message: 'Пополните баланс звонков',
				}),
			);
			dispatch(setShowBalanceAlert(true));
		}
	}, []);

	const showBalanceModal = () => {
		dispatch(
			setBalanceModal({
				show: true,
				type: profile.data.balance === 0 ? codeTypes.number : codeTypes.sms
			})
		);
	}

	const requestTrialLicense = () => {
		dispatch(
			setBitrixModal({
				show: true
			})
		);
	}

	return (
		<div className="license_page">
			{
				profile.data.balance === 0 ?
					<>
						<h2>Необходимо пополнить счет</h2>
						<div className={'license_page__balances'}>
							<p className="balance_card__form_count_info">
								Ваш баланс составляет <span>{profile.data.balance}</span> звонков.{' '}
							</p>
							<button
								className="license_page__balances_link"
								onClick={showBalanceModal}
							>
								<span>Введите лицензионный ключ</span>
							</button>
							<button
								className="license_page__balances_link"
								onClick={requestTrialLicense}
							>
								<span>
									Запросите триальную лицензию
								</span>
							</button>
							<a href={MARKET_LINK_BUY} target="_blank" rel="noreferrer">
									<span className="license_page__balances_link">
										Купите ключ в онлайн-магазине Kloud.one
									</span>
							</a>
						</div>
					</> : <>
						<div className={'license_page__balances'}>
							<h2>Баланс {profile.data.balance} звонков</h2>
							<button
								className="license_page__balances_link"
								onClick={showBalanceModal}
							>
								<span>Введите лицензионный ключ</span>
							</button>
							<a href={MARKET_LINK_BUY} target="_blank" rel="noreferrer">
									<span className="license_page__balances_link">
										Купите ключ в онлайн-магазине Kloud.one
									</span>
							</a>
						</div>
					</>
			}
			<LicenseTable />
		</div>
	);
};

export default LicensePage;
