import React from 'react';
import { Snackbar, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		'& .MuiSnackbarContent-root': {
			background: '#273270',
		},
	},
});

const AlertMessageSnackbar = ({ open, close, message }) => {
	const classes = useStyles();

	const onClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		close();
	};

	return (
		<Snackbar
			className={classes.root}
			open={open}
			onClose={onClose}
			autoHideDuration={5000}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			message={message}
			TransitionComponent={(props) => <Slide {...props} direction="left" />}
			action={
				<React.Fragment>
					<CloseIcon onClick={close} />
				</React.Fragment>
			}
		/>
	);
};

export default AlertMessageSnackbar;
