import React from 'react';
import './style.css';
import { logoutAuth } from '../../api/auth'

const Logout = () => {
	const logout = async () => {
		await logoutAuth();
	};
	return (
		<div className="logout_button">
			<button className='button' onClick={logout}>
				Выйти
			</button>
		</div>
	);
};

export default Logout;
