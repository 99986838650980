import React from 'react';
import UsersTable from '../tables/usersTable';

// import './style.css';

const Users = () => {
	return (
		<div className="balances_container">
			<h2>Список пользователей</h2>
			<UsersTable />
		</div>
	);
};

export default Users;
