import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.css';

const CustomExpandIcon = (props) => {
	const { expanded } = props;
	return (
		<div
			className={
				expanded ? 'custom_expand_icon expanded' : 'custom_expand_icon'
			}
		>
			<ExpandMoreIcon />
		</div>
	);
};

export default CustomExpandIcon;
