import React from 'react';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';

const CustomButton = (props) => {
	const { onClick, text, disabled} = props;

	return (
		<div className="custom_button" onClick={onClick} {...props}>
			{disabled ? (
				<LoadingButton
					size="large"
					loading={disabled}
					loadingPosition="center"
					loadingIndicator={
						<CircularProgress size={22} thickness={3} disableShrink={true} />
					}
					disabled={disabled}
				/>
			) : (
				text
			)}
		</div>
	);
};

export default CustomButton;
