import { createSlice } from '@reduxjs/toolkit';

export const modalsSlice = createSlice({
	name: 'modals',
	initialState: {
		addAppModal: {
			show: false,
		},
		balanceModal: {
			show: false,
		},
		editAppModal: {
			show: false,
			appId: '',
		},
		deleteAppModal: {
			show: false,
			appId: '',
		},
		bitrixModal: {
			show: false,
		}
	},
	reducers: {
		setAddAppModal: (state, data) => {
			state.addAppModal = data.payload;
		},
		setBalanceModal: (state, data) => {
			state.balanceModal = data.payload;
		},
		setEditAppModal: (state, data) => {
			state.editAppModal = data.payload;
		},
		setDeleteAppModal: (state, data) => {
			state.deleteAppModal = data.payload;
		},
		setBitrixModal: (state, data) => {
			state.bitrixModal = data.payload;
		},
	},
});

export const {
	setAddAppModal,
	setEditAppModal,
	setDeleteAppModal,
	setBalanceModal,
	setBitrixModal
} = modalsSlice.actions;

export default modalsSlice.reducer;
