import React from 'react';
import IconTooltip from '../../tooltips/iconTooltip';
import './style.css';

const ArrowSort = (props) => {
	const { onClick, direction } = props;
	return (
		<div className="sort_icon">
			<IconTooltip
				title={
					direction === 'desc'
						? 'Обратный порядок сортировки'
						: 'Прямой порядок сортировки'
				}
			>
				<div
					className={direction === 'desc' ? 'sort_icon' : 'sort_icon rotate'}
					onClick={onClick}
				>
					<svg
						width="11"
						height="14"
						viewBox="0 0 11 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M10.3869 7.81938V7.81899C10.0645 7.49653 9.5367 7.49653 9.21425 7.81899L5.64764 11.3856L2.08143 7.81899C1.75898 7.49653 1.23122 7.49653 0.908761 7.81899V7.81938C0.586306 8.14184 0.585914 8.6692 0.908761 8.99166L4.89655 12.9795C5.0999 13.1828 5.38435 13.258 5.64764 13.2051C5.91094 13.258 6.19578 13.1828 6.39913 12.9795L10.3869 8.99166C10.7098 8.6692 10.7094 8.14184 10.3869 7.81938Z"
							fill="#8387A0"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6.49396 1.6064L6.49396 12.222C6.49396 12.678 6.12096 13.051 5.6649 13.051H5.66451C5.20845 13.051 4.83545 12.678 4.83545 12.222L4.83545 1.6064C4.83545 1.15034 5.20845 0.777344 5.66451 0.777344H5.6649C6.12096 0.777344 6.49396 1.15034 6.49396 1.6064Z"
							fill="#8387A0"
						/>
					</svg>
				</div>
			</IconTooltip>
		</div>
	);
};

export default ArrowSort;
