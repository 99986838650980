import React from 'react';
import IconTooltip from '../../tooltips/iconTooltip';
import './style.css';
import CustomPopover from '../../popovers/CustomPopover';
import { URL_SERVICES, URL_SERVICE_CRM_TOOLS, URL_SERVICE_TRUSTED } from '../../../utils/constants';
import AppIconButton from '../../icons/appIcon';
import GetterIcon from '../../icons/getter';
import AutoCallIcon from '../../icons/autocall';
import CrmTools from '../../icons/crmtools';
import AuthorizationIcon from '../../icons/authorization';

const LinksServices = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const navProfileRef = React.useRef(null);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const showMenu = () => {
		setAnchorEl(navProfileRef.current);
	};

	const onClickMenuItem = (serviceName) => {
		handleClose();
		window.open(`${URL_SERVICES}/${serviceName}`);
	};

	const onClickMenuServiceCrmTools = () => {
		handleClose();
		window.open(`${URL_SERVICE_CRM_TOOLS}`, '_blank')
	};

	const onClickMenuServiceTrusted = () => {
		handleClose();
		window.open(`${URL_SERVICE_TRUSTED}`, '_blank')
	};

	return (
		<div className={'links_services_button_wrapper'}>
			<IconTooltip title="Приложения платформы kloud.one">
				<div
					className={'links_services_button'}
					onClick={showMenu}
					ref={navProfileRef}
				>
					<AppIconButton />
				</div>
			</IconTooltip>
			<CustomPopover
				open={open}
				anchorEl={anchorEl}
				handleClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				ChildComponent={() => {
					return (
						<div className={'links_services_button__menu'}>
							<div
								className={'links_services_button__menu_item'}
								onClick={() => onClickMenuItem('counter')}
							>
								<GetterIcon />
								<span>{'Прием показаний счетчиков'}</span>
							</div>
							<div
								className={'links_services_button__menu_item'}
								onClick={() => onClickMenuItem('obzvon')}
							>
								<AutoCallIcon />
								<span>{'Обзвон должников'}</span>
							</div>
							<div
								className={'links_services_button__menu_item'}
								onClick={() => onClickMenuServiceCrmTools()}
							>
								<CrmTools />
								<span>{'Обработка заказов'}</span>
							</div>
							<div
								className={'links_services_button__menu_item'}
								onClick={() => onClickMenuServiceTrusted()}
							>
								<AuthorizationIcon />
								<span>{'Сервис аутентификации'}</span>
							</div>
						</div>
					);
				}}
			/>
		</div>
	);
};

export default LinksServices;
