import React from 'react';
import './style.css';
import IconTooltip from '../../tooltips/iconTooltip';

const CopyIcon = (props) => {
	const { onClick } = props;
	return (
		<div className="copy_icon">
			<IconTooltip title="Копировать">
				<div className="copy_icon__icon" onClick={onClick}>
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.00951 3.85693H10.1403C12.5377 3.85693 14.1429 5.53991 14.1429 8.04338V13.8214C14.1429 16.3168 12.5377 17.9998 10.1403 17.9998H4.00951C1.61214 17.9998 0 16.3168 0 13.8214V8.04338C0 5.53991 1.61214 3.85693 4.00951 3.85693Z"
							fill="#90A0B7"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M14.3613 0H8.78782C7.03112 0 5.7382 0.99402 5.30273 2.57146H11.4259C13.8233 2.57146 15.4285 4.25443 15.4285 6.7579V12.5359C15.4285 12.5989 15.4275 12.6615 15.4254 12.7235C17.0026 12.3048 18 10.9341 18 9.05859V3.80586C18 1.52997 16.5407 0 14.3613 0Z"
							fill="#90A0B7"
						/>
					</svg>
				</div>
			</IconTooltip>
		</div>
	);
};

export default CopyIcon;
