import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './actions/profile';
import sessionReducer from './actions/session';
import balancesReducer from './actions/balances';
import callsReducer from './actions/calls';
import appsReducer from './actions/apps';
import usersReducer from './actions/users';
import adminsReducer from './actions/admins';
import sipTrunksReducer from './actions/siptrunks';
import pageReducer from './actions/page';
import alertReducer from './actions/alert';
import modalsReducer from './actions/modals';

export default configureStore({
	reducer: {
		profile: profileReducer,
		session: sessionReducer,
		balances: balancesReducer,
		calls: callsReducer,
		apps: appsReducer,
		users: usersReducer,
		admins: adminsReducer,
		siptrunks: sipTrunksReducer,
		page: pageReducer,
		alert: alertReducer,
		modals: modalsReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
