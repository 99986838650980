import React from 'react';
import './style.css';

const Preloader = ({ propRef }) => {
	return (
		<div className="preloader" ref={propRef}>
			<svg className="intro go" viewBox="0 0 100 88">
				<text
					textAnchor="start"
					x="10"
					y="50"
					className="text text-stroke"
					clipPath="url(#text1)"
				>
					KLOUD.ONE
				</text>
				<defs>
					<clipPath id="text1">
						<text textAnchor="start" x="10" y="50" className="text">
							KLOUD.ONE
						</text>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default Preloader;
