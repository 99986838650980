import React from 'react';
import DeleteIconMuI from '@mui/icons-material/Delete';
import IconTooltip from '../../tooltips/iconTooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiSvgIcon-root:hover': {
			fill: '#2186F0',
		},
	},
}));

const DeleteIcon = (props) => {
	const { onClick } = props;
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<IconTooltip title="Удалить">
				<DeleteIconMuI
					style={{ color: '#8387A0' }}
					fontSize="medium"
					onClick={onClick}
				/>
			</IconTooltip>
		</div>
	);
};

export default DeleteIcon;
