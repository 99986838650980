import axios from 'axios';
import { URL_SERVER } from '../utils/constants';
import { setSession } from '../redux/actions/session';
import store from '../redux/store';
import { setDataCalls, setTotalSizeCalls } from '../redux/actions/calls';

const createRequest = (app, stateApp, stateCalls) => {
	let query = '';
	let app_id;
	if (app === 'all') {
		app_id = stateApp.data.map((item) => item.client_id);
	} else app_id = [app];
	if (
		(stateCalls.filter && Object.keys(stateCalls.filter).length) ||
		(stateCalls.searchOption === 'period' &&
			stateCalls.searchStart &&
			stateCalls.searchEnd)
	) {
		const filteredFields = Object.keys(stateCalls.filter);
		const arrQuery = filteredFields.map((item, i) => {
			return `{"${filteredFields[i]}":{"$regex":"${
				stateCalls.filter[filteredFields[i]].filterVal
			}"}}`;
		});
		if (
			stateCalls.searchOption === 'period' &&
			stateCalls.searchStart &&
			stateCalls.searchEnd
		) {
			arrQuery.push(
				`{"$and":[{"createAt":{"$gt":"${new Date(
					stateCalls.searchStart.replace(' ', 'T')
				).toISOString()}"}},{"createAt":{"$lt":"${new Date(
					stateCalls.searchEnd.replace(' ', 'T')
				).toISOString()}"}}]}`
			);
		}

		query = `"$and":[${arrQuery.join(',')},{"client_id":{"$in":["${app_id.join(
			'","'
		)}"]}}]`;
	} else {
		query = `"client_id":{"$in":["${app_id.join('","')}"]}	`;
	}
	let sort;
	if (!Object.keys(stateCalls.sort).length) {
		sort = '';
	} else {
		sort =
			stateCalls.sort.sortOrder === 'asc'
				? `"${stateCalls.sort.sortField}": 1`
				: `"${stateCalls.sort.sortField}":-1`;
	}
	const limit = stateCalls.sizePerPage;
	const skip = (stateCalls.page - 1) * stateCalls.sizePerPage;
	return { limit, skip, sort, query };
};

export const getCalls = async () => {
	const stateCalls = store.getState().calls;
	const stateApp = store.getState().apps;
	const { limit, skip, sort, query } = createRequest(
		stateApp.selected,
		stateApp,
		stateCalls
	);
	await axios
		.get(
			`${URL_SERVER}/api/v1/calls?limit=${limit}&skip=${skip}&sort={${sort}}&query={${query}}`,
			{ withCredentials: true }
		)
		.then((res) => {
			store.dispatch(setDataCalls(res.data));
			store.dispatch(setTotalSizeCalls(Number(res.headers['x-total-count'])));
		})
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			}
		});
};

export const getMoreCalls = async () => {
	const stateCalls = store.getState().calls;
	const stateApp = store.getState().apps;
	const { limit, skip, sort, query } = createRequest(
		stateApp.selected,
		stateApp,
		stateCalls
	);
	await axios
		.get(
			`${URL_SERVER}/api/v1/calls?limit=${limit}&skip=${skip}&sort={${sort}}&query={${query}}`,
			{ withCredentials: true }
		)
		.then((res) => {
			let stateToStore = [...stateCalls.data];
			const stateArrayId = stateToStore.map((item) => item.id);
			const newItemArray = [];
			res.data.map((item) => {
				if (!stateArrayId.includes(item.id)) {
					newItemArray.push(item);
				}
				return null;
			});
			stateToStore = stateToStore.concat(newItemArray);
			store.dispatch(setDataCalls(stateToStore));
			store.dispatch(setTotalSizeCalls(Number(res.headers['x-total-count'])));
		})
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			}
		});
};
