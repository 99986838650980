import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	arrow: {
		color: '#000',
		width: 60,
	},
	tooltip: {
		backgroundColor: '#000',
		color: '#FFFFFF',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '13px',
		lineHeight: '18px',
		padding: '8.5px 12px 9.5px',
		boxShadow:
			'0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16);',
		borderRadius: 2,
		marginLeft: 23,
	},
}));

const IconTooltip = (props) => {
	const classes = useStyles();
	return (
		<Tooltip arrow classes={classes} placement="bottom-start" {...props} />
	);
};

export default IconTooltip;
