import React from 'react';
import './style.css';
import { useSelector } from 'react-redux';
import SettingsSip from '../settingsSip';
import AddConnection from '../buttons/addConnection';
import { getSipTruncks } from '../../api/truncks';
import { codeTypes } from '../../utils/constants';

const ConnectionsSip = () => {
	const connections = useSelector((state) => state.siptrunks);

	React.useEffect(() => {
		getSipTruncks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="connections_sip">
			{connections.dataSip.map((connection) => (
				<SettingsSip connection={connection} type={codeTypes.number} key={connection.id} />
			))}
			<AddConnection type={codeTypes.number}/>
		</div>
	);
};

export default ConnectionsSip;
