import { createSlice } from '@reduxjs/toolkit';

export const sipTrunksSlice = createSlice({
	name: 'connections',
	initialState: {
		dataSip: [],
		dataSms: [],
	},
	reducers: {
		setDataSipTrunks: (state, data) => {
			state.dataSip = data.payload;
		},
		setDataSmsTrunks: (state, data) => {
			state.dataSms = data.payload;
		},
	},
});

export const { setDataSipTrunks, setDataSmsTrunks } = sipTrunksSlice.actions;

export default sipTrunksSlice.reducer;
