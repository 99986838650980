import React from 'react';

export default function AuthorizationIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M7.67811 1.63184H2.25V23.3675H7.67811V1.63184Z" fill="#98CFFA" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.2441 23.375C17.1628 23.3733 21.9606 18.5047 21.9606 12.5C21.9606 6.49529 17.1628 1.62672 11.2441 1.625V7.10568C14.1789 7.1074 16.5581 9.5224 16.5581 12.5C16.5581 15.4776 14.1789 17.8926 11.2441 17.8944V23.375Z"
				fill="#2196F3"
			/>
		</svg>
	);
}
