import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
	name: 'users',
	initialState: {
		data: [],
		sort: {},
		filter: {},
		page: 1,
		sizePerPage: 10,
		totalSize: 0,
	},
	reducers: {
		setDataUsers: (state, data) => {
			state.data = data.payload;
		},
		setTotalSizeUsers: (state, totalSize) => {
			state.totalSize = totalSize.payload;
		},
		setPageUsers: (state, page) => {
			state.page = page.payload;
		},
		setSizePerPageUsers: (state, sizePerPage) => {
			state.sizePerPage = sizePerPage.payload;
		},
		setSortUsers: (state, sort) => {
			state.sort = sort.payload;
		},
		setFilterUsers: (state, filter) => {
			state.filter = filter.payload;
		},
	},
});

export const {
	setDataUsers,
	setTotalSizeUsers,
	setPageUsers,
	setSizePerPageUsers,
	setSortUsers,
	setFilterUsers,
} = usersSlice.actions;

export default usersSlice.reducer;
