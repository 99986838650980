import React from 'react';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import { URL_SERVER } from '../../utils/constants';
import { setDataUsers, setTotalSizeUsers } from '../../redux/actions/users';
import { setSession } from '../../redux/actions/session';
import { useSelector, useDispatch } from 'react-redux';

const UserSwitcher = (props) => {
	const { user, createRequest } = props;
	const state = useSelector((state) => state.users);
	const dispatch = useDispatch();
	const [checked, setChecked] = React.useState(user.active ? true : false);

	const handleChange = (e) => {
		console.log(user.id);
		setChecked(e.target.checked);
		(async () => {
			const { limit, skip, sort, query } = createRequest(state);
			await axios
				.patch(
					`${URL_SERVER}/api/admin/v1/users/${user.id}`,
					{ active: e.target.checked },
					{ withCredentials: true }
				)
				.then(async (res) => {
					await axios
						.get(
							`${URL_SERVER}/api/admin/v1/users?limit=${limit}&skip=${skip}&sort={${sort}}&query={${query}}`,
							{ withCredentials: true }
						)
						.then((res) => {
							dispatch(setDataUsers(res.data));
							dispatch(setTotalSizeUsers(Number(res.headers['x-total-count'])));
						})
						.catch((err) => {
							if (err.message.includes('401')) {
								console.log(err.message);
								dispatch(setSession(false));
							}
						});
				})
				.catch((err) => {
					if (err.message.includes('401')) {
						console.log(err.message);
						dispatch(setSession(false));
					}
				});
		})();
	};

	return (
		<div>
			<Switch
				checked={checked}
				onChange={handleChange}
				color="primary"
				name="checked"
				inputProps={{ 'aria-label': 'primary checkbox' }}
			/>
		</div>
	);
};

export default UserSwitcher;
