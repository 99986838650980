import React from 'react';
import './style.css';
import { LICENSE_POLICY, PERSONAL_DATA_POLICY, VERSION_TEXT } from '../../utils/constants';

const Footer = () => {
	return (
		<div className={'footer'}>
			<div className="footer__centralized_block">
				<div className="footer__left">
					<span>{`Версия ${VERSION_TEXT}`}</span>
					<span> © ООО Телефонные системы</span>
				</div>
				<div className="footer__right">
					<a href={LICENSE_POLICY} target="_blank" rel="noreferrer">
						<span>Лицензионная политика</span>
					</a>
					<a href={PERSONAL_DATA_POLICY} target="_blank" rel="noreferrer">
						<span>Политика обработки ПДн</span>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
