import React from 'react';
import Modal from '@mui/material/Modal';
import ReactDOM from 'react-dom';
import styles from './BitrixModal.module.css';
import { BITRIX_FORM } from '../../../utils/constants';

const BitrixModal = (props) => {
	const { open, setClose } = props;
		
	const closeModal = () => {
		setClose(false);
	};
	console.log(document.getElementById('modal'));

	setTimeout(() => {
		const scriptWrapper = document.createElement('script');
		scriptWrapper.setAttribute('data-b24-form','inline/34/44qi8k');
		scriptWrapper.setAttribute('data-skip-moving','true');
		const script = document.createElement('script');
		// eslint-disable-next-line no-useless-concat
		script.src = BITRIX_FORM+"?"+(Date.now()/180000|0);
		script.async = true;
		scriptWrapper.appendChild(script);
		const bitrix = document.getElementById('bitrix');
		bitrix?.appendChild(scriptWrapper)
	}, 100)

	return ReactDOM.createPortal(
		<Modal
			open={open}
			onClose={closeModal}
		>
			<div id="bitrix" className={styles.new_script}>
			</div>
		</Modal>,
		document.getElementById('modal')
	);
};

export default BitrixModal;