import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
	name: 'alert',
	initialState: {
		data: {
			show: false,
			message: '',
		}
	},
	reducers: {
		setDataAlert: (state, data) => {
			state.data = data.payload;
		},
	},
});

export const { setDataAlert } = alertSlice.actions;

export default alertSlice.reducer;
