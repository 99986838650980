import React from 'react';
import Logo from '../logo/index';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		marginTop: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',
		'& .MuiButton-outlinedPrimary': {
			color: 'dodgerblue',
		},
		'& .MuiButton-root': {
			borderRadius: 0,
		},
	},
});

const HeaderAdminPanel = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Logo />
		</div>
	);
};

export default HeaderAdminPanel;
