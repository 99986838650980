import React from 'react';
import './style.css';

const CancelButton = (props) => {
	const { onClick } = props;
	return (
		<button className="cencel_button" onClick={onClick} {...props}>
			Отмена
		</button>
	);
};

export default CancelButton;
