import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import questionIcon from '../../images/icons/question-letter.png';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import CircleIcon from '@mui/icons-material/Circle';
import { makeStyles } from '@material-ui/core/styles';
import './style.css';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiPaper-elevation1': {
			boxShadow: 'none',
		},
		'& .MuiAccordionSummary-root': {
			border: '1px solid #E0E2E7',
			'&:hover': {
				background: '#F6F6FB',
			},
			// eslint-disable-next-line no-useless-computed-key
			['@media screen and (max-device-width: 1000px)']: {
				borderLeft: 'none',
				borderRight: 'none',
			},
		},
		'& .MuiAccordionSummary-root.Mui-expanded': {
			borderBottom: '1px solid #E0E2E7',
		},
		'& .MuiIconButton-edgeEnd': {
			marginRight: 4,
		},
		'& .MuiIconButton-label': {
			background: '#F6F6FB',
			borderRadius: '50%',
			width: 24,
			height: 24,
		},
		'& .MuiAccordionSummary-root:hover .MuiIconButton-label': {
			background: '#2186F0',
		},
		'& .MuiAccordionSummary-root:hover .MuiSvgIcon-root': {
			fill: '#fff',
		},
		'& .MuiAccordion-root.Mui-expanded': {
			margin: 0,
			borderBottom: '1px solid #E0E2E7',
		},
		'& .MuiAccordionSummary-content.Mui-expanded': {
			margin: '12px 0',
		},
		'& .MuiAccordionDetails-root': {
			background: '#FCFCFE',
			border: '1px solid #E0E2E7',
			borderTop: 'none',
			borderBottom: 'none',
			padding: '18px 36px',
			// eslint-disable-next-line no-useless-computed-key
			['@media screen and (max-device-width: 1000px)']: {
				borderLeft: 'none',
				borderRight: 'none',
			},
		},
		'& .MuiStepLabel-label': {
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '22px',
			color: '#273270',
		},
		'& .MuiStepLabel-label.Mui-active': {
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '22px',
			color: '#273270',
		},
		'& .MuiStepLabel-labelContainer': {
			marginLeft: 15,
		},
		'& .MuiStepConnector-root': {
			marginLeft: 5,
			height: 25,
		},
		'& .MuiStepContent-root': {
			marginLeft: 5,
			height: 33,
		},
		'& .MuiCollapse-wrapperInner': {
			textAlign: 'left',

			'& a': {
				marginLeft: 8,
				fontFamily: 'Inter',
				fontStyle: 'normal',
				fontWeight: 'normal',
				fontSize: '15px',
				lineHeight: '22px',
				color: '#2186F0',
				textDecoration: 'none',
				'&:hover': {
					textDecoration: 'underline',
				},
			},
		},
	},
}));

const Icon = () => {
	return (
		<CircleIcon
			fontSize="small"
			color="primary"
			style={{ color: '#2186F0', paddingLeft: 2, width: 8, height: 8 }}
		/>
	);
};

const AccordionGetStarted = (props) => {
	const { expanded, change } = props;
	const classes = useStyles();

	const changeExpanded = (e, isExpanded) => {
		change(isExpanded);
	};

	return (
		<div className={classes.root}>
			<Accordion
				className="accordion_get_started"
				expanded={expanded}
				onChange={changeExpanded}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="get-started"
				>
					<div className="accordion_get_started__header">
						<div className="question_icon">
							<img src={questionIcon} alt="question-icon" />
						</div>
						<span>С чего начать?</span>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<Stepper orientation="vertical">
						<Step active={false}>
							<StepLabel StepIconComponent={Icon}>
								Создайте приложение
							</StepLabel>
						</Step>
						<Step active={true}>
							<StepLabel StepIconComponent={Icon}>
								Используйте API для взаимодействия с Kloud.One{' '}
							</StepLabel>
							<StepContent>
								<a
									href="https://app.swaggerhub.com/apis/DigtTelecom/oauth2.id.kloud.one/1.0.0"
									target="_blank"
									rel="noreferrer"
								>
									<span>Документация</span>
								</a>
							</StepContent>
						</Step>
						<Step active={true}>
							<StepLabel StepIconComponent={Icon}>
								Добавьте страницу входа Kloud.One на свой ресурс
							</StepLabel>
							<StepContent>
								<a
									href="https://app.swaggerhub.com/apis/DigtTelecom/id.kloud.one/1.0.0"
									target="_blank"
									rel="noreferrer"
								>
									<span>Документация</span>
								</a>
							</StepContent>
						</Step>
					</Stepper>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default AccordionGetStarted;
