import React from 'react';
import { Snackbar, Slide } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const AlertMessage = ({ open, close, severity, message, refSnackBar }) => {
	return (
		<Snackbar
			open={open}
			onClose={close}
			ref={refSnackBar}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			TransitionComponent={(props) => <Slide {...props} direction="left" />}
		>
			<Alert
				severity={severity}
				variant="filled"
				sx={{ width: '100%' }}
				onClose={close}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default AlertMessage;
