import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import Input from '@material-ui/core/Input';
import axios from 'axios';
import { URL_SERVER } from '../../utils/constants';
import { setSession } from '../../redux/actions/session';
import { setDataProfile } from '../../redux/actions/profile';
import Paper from '@material-ui/core/Paper';

const AdminProfile = () => {
	const state = useSelector((state) => state.profile);
	const dispatch = useDispatch();

	const editName = () => {
		document.querySelector('.profile__name').classList.add('hidden');
		document.querySelector('.profile__name_edit input').value = state.data.name;
		document.querySelector('.profile__name_edit').classList.remove('hidden');
	};

	const saveName = () => {
		(async () => {
			const newName = document.querySelector('.profile__name_edit input').value;

			await axios
				.patch(
					`${URL_SERVER}/api/v1/users/${state.data.id}`,
					{ name: newName },
					{ withCredentials: true }
				)
				.then((res) => {
					dispatch(setDataProfile(res.data));
				})
				.catch((err) => {
					if (err.message.includes('401')) {
						console.log(err.message);
						dispatch(setSession(false));
					}
				});
		})();
		document.querySelector('.profile__name_edit').classList.add('hidden');
		document.querySelector('.profile__name').classList.remove('hidden');
	};

	return (
		<div className="profile">
			<Paper elevation={3} className="profile__wrapper">
				<div className="profile__name">
					<h2>{state.data ? state.data.name : ''}</h2>
					<EditIcon onClick={editName} className="profile__edit_icon" />
				</div>
				<div className="profile__name_edit hidden">
					<Input id="standard-basic" placeholder="Укажите ваше имя" />
					<CheckIcon onClick={saveName} className="profile__edit_icon" />
				</div>
				<div className="profile__list_item">
					<span>Телефон</span>
					<span>
						<b>{state.data ? state.data.phone : '0'}</b>
					</span>
				</div>
			</Paper>
		</div>
	);
};

export default AdminProfile;
