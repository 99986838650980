import React from 'react';

export default function Apps() {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.2297 5.4321L11.9966 3.66519L13.7635 5.4321L11.9966 7.19902L10.2297 5.4321ZM8.81545 5.4321L9.52256 4.725L11.2895 2.95808L11.9966 2.25098L12.7037 2.95808L14.4706 4.725L15.1777 5.4321L14.4706 6.13921L12.7037 7.90612L11.9966 8.61323L11.2895 7.90612L9.52256 6.13921L8.81545 5.4321ZM3.99902 4.18271H6.49782V6.6815H3.99902V4.18271ZM2.99902 3.18271H3.99902H6.49782H7.49782V4.18271V6.6815V7.6815H6.49782H3.99902H2.99902V6.6815V4.18271V3.18271ZM3.99902 10.9309H6.49782V13.4297H3.99902V10.9309ZM2.99902 9.93089H3.99902H6.49782H7.49782V10.9309V13.4297V14.4297H6.49782H3.99902H2.99902V13.4297V10.9309V9.93089ZM13.246 10.9309H10.7472V13.4297H13.246V10.9309ZM10.7472 9.93089H9.74721V10.9309V13.4297V14.4297H10.7472H13.246H14.246V13.4297V10.9309V9.93089H13.246H10.7472Z"
				fill="#333333"
			/>
		</svg>
	);
}
