import React from 'react';
import './style.css';

const CustomButtonWithPlus = (props) => {
	const { onClick, text } = props;
	return (
		<button className="custom_button__plus" onClick={onClick} {...props}>
			<svg
				className="button_plus"
				width="12"
				height="12"
				viewBox="0 0 12 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M11.2493 5.41675H6.58272V0.750046C6.58272 0.428116 6.32135 0.166748 5.99931 0.166748C5.67738 0.166748 5.41602 0.428116 5.41602 0.750046V5.41675H0.749313C0.427383 5.41675 0.166016 5.67812 0.166016 6.00005C0.166016 6.32208 0.427383 6.58345 0.749313 6.58345H5.41602V11.25C5.41602 11.5721 5.67738 11.8335 5.99931 11.8335C6.32135 11.8335 6.58272 11.5721 6.58272 11.25V6.58345H11.2493C11.5714 6.58345 11.8327 6.32208 11.8327 6.00005C11.8327 5.67812 11.5714 5.41675 11.2493 5.41675Z"
					fill="white"
					stroke="white"
					strokeWidth="0.3"
				/>
			</svg>
			<span>{text ? text : 'Кнопка'}</span>
		</button>
	);
};

export default CustomButtonWithPlus;
