import React from 'react';
import './style.css';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { URL_SERVER } from '../../utils/constants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		'& .rs-picker-default .rs-picker-toggle': {
			border: 'none',
			padding: '9px 11px',
		},
		'& .rs-picker-menu': {
			borderRadius: 3,
		},
		'& .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value':
			{
				color: '#273270',
				opacity: 0,
			},
		'& .rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret': {
			backgroundImage: `url(${URL_SERVER}/date-icon.png)`,
			'&:hover': {
				backgroundImage: `url(${URL_SERVER}/date-icon-color.png)`,
			},
		},
	},
}));

const locale = {
	sunday: 'Вс',
	monday: 'Пн',
	tuesday: 'Вт',
	wednesday: 'Ср',
	thursday: 'Чт',
	friday: 'Пт',
	saturday: 'Сб',
	ok: 'Применить',
	today: 'Сегодня',
	yesterday: 'Завтра',
	last7Days: 'Последние 7 дней',
};

const pickerStyle = {
	width: 310,
	height: 40,
	fontFamily: 'Inter',
	fontStyle: 'normal',
	fontWeight: 'normal',
	fontSize: '15px',
	lineHeight: '22px',
	color: '#273270',
};

const CustomDatePicker = (props) => {
	const classes = useStyles();
	const { startDate, endDate, onChangeDate } = props;
	const handleStartDate = `${
		new Date(startDate).getDate() < 10
			? `0${new Date(startDate).getDate()}`
			: new Date(startDate).getDate()
	}.${
		new Date(startDate).getMonth() + 1 < 10
			? `0${new Date(startDate).getMonth() + 1}`
			: new Date(startDate).getMonth() + 1
	}.${new Date(startDate).getFullYear()}`;
	const handleEndDate = `${
		new Date(endDate).getDate() < 10
			? `0${new Date(endDate).getDate()}`
			: new Date(endDate).getDate()
	}.${
		new Date(endDate).getMonth() + 1 < 10
			? `0${new Date(endDate).getMonth() + 1}`
			: new Date(endDate).getMonth() + 1
	}.${new Date(endDate).getFullYear()}`;
	const [startDateValue, setStartDateValue] = React.useState(handleStartDate);
	const [endDateValue, setEndDateValue] = React.useState(handleEndDate);

	const changeDates = ([start, end]) => {
		onChangeDate(start, end);
	};

	const onChangeStartDateValue = (e) => {
		const value = e.target.value.replace(/\./g, '');
		if (value.length < 9 && /\d+$/.test(value)) {
			if (value.length && value.length < 3) {
				setStartDateValue(Number(value) > 31 ? '31' : value);
			} else if (value.length && value.length === 3) {
				setStartDateValue(
					`${
						Number(value.slice(0, 2)) > 31 ? '31' : value.slice(0, 2)
					}.${value.slice(2, 3)}`
				);
			} else if (value.length && value.length === 4) {
				setStartDateValue(
					`${
						Number(value.slice(0, 2)) > 31 &&
						[1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
							? '31'
							: Number(value.slice(0, 2)) > 30 &&
							  [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
							? '30'
							: Number(value.slice(0, 2)) > 28 &&
							  [2].includes(Number(value.slice(2, 4)))
							? '28'
							: value.slice(0, 2)
					}.${Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)}`
				);
			} else if (value.length && value.length < 9) {
				setStartDateValue(
					`${
						Number(value.slice(0, 2)) > 31 &&
						[1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
							? '31'
							: Number(value.slice(0, 2)) > 30 &&
							  [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
							? '30'
							: Number(value.slice(0, 2)) > 28 &&
							  [2].includes(Number(value.slice(2, 4)))
							? '28'
							: value.slice(0, 2)
					}.${
						Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)
					}.${value.slice(4, 9)}`
				);
			}
			if (value.length && value.length === 8) {
				const val = `${
					Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)
				}.${
					Number(value.slice(0, 2)) > 31 &&
					[1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
						? '31'
						: Number(value.slice(0, 2)) > 30 &&
						  [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
						? '30'
						: Number(value.slice(0, 2)) > 28 &&
						  [2].includes(Number(value.slice(2, 4)))
						? '28'
						: value.slice(0, 2)
				}.${value.slice(4, 9)}`;
				onChangeDate(new Date(val), endDate);
			}
		} else if (!value.length) {
			setStartDateValue('');
		}
	};

	const onChangeEndDateValue = (e) => {
		const value = e.target.value.replace(/\./g, '');
		if (value.length < 9 && /\d+$/.test(value)) {
			if (value.length && value.length < 3) {
				setEndDateValue(Number(value) > 31 ? '31' : value);
			} else if (value.length && value.length === 3) {
				setEndDateValue(
					`${
						Number(value.slice(0, 2)) > 31 ? '31' : value.slice(0, 2)
					}.${value.slice(2, 3)}`
				);
			} else if (value.length && value.length === 4) {
				setEndDateValue(
					`${
						Number(value.slice(0, 2)) > 31 &&
						[1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
							? '31'
							: Number(value.slice(0, 2)) > 30 &&
							  [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
							? '30'
							: Number(value.slice(0, 2)) > 28 &&
							  [2].includes(Number(value.slice(2, 4)))
							? '28'
							: value.slice(0, 2)
					}.${Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)}`
				);
			} else if (value.length && value.length < 9) {
				setEndDateValue(
					`${
						Number(value.slice(0, 2)) > 31 &&
						[1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
							? '31'
							: Number(value.slice(0, 2)) > 30 &&
							  [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
							? '30'
							: Number(value.slice(0, 2)) > 28 &&
							  [2].includes(Number(value.slice(2, 4)))
							? '28'
							: value.slice(0, 2)
					}.${
						Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)
					}.${value.slice(4, 9)}`
				);
			}
			if (value.length && value.length === 8) {
				const val = `${
					Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)
				}.${
					Number(value.slice(0, 2)) > 31 &&
					[1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
						? '31'
						: Number(value.slice(0, 2)) > 30 &&
						  [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
						? '30'
						: Number(value.slice(0, 2)) > 28 &&
						  [2].includes(Number(value.slice(2, 4)))
						? '28'
						: value.slice(0, 2)
				}.${value.slice(4, 9)}`;
				onChangeDate(startDate, new Date(val));
			}
		} else if (!value.length) {
			setEndDateValue('');
		}
	};

	const select = (e) => {
		// console.log(e);
	};

	React.useEffect(() => {
		setStartDateValue(
			`${
				new Date(startDate).getDate() < 10
					? `0${new Date(startDate).getDate()}`
					: new Date(startDate).getDate()
			}.${
				new Date(startDate).getMonth() + 1 < 10
					? `0${new Date(startDate).getMonth() + 1}`
					: new Date(startDate).getMonth() + 1
			}.${new Date(startDate).getFullYear()}`
		);
		setEndDateValue(
			`${
				new Date(endDate).getDate() < 10
					? `0${new Date(endDate).getDate()}`
					: new Date(endDate).getDate()
			}.${
				new Date(endDate).getMonth() + 1 < 10
					? `0${new Date(endDate).getMonth() + 1}`
					: new Date(endDate).getMonth() + 1
			}.${new Date(endDate).getFullYear()}`
		);
	}, [startDate, endDate]);

	return (
		<div className={classes.root}>
			<div className="date_block">
				<input
					type="text"
					name="date"
					className="date_input"
					placeholder="дд.мм.гггг"
					value={startDateValue}
					onChange={onChangeStartDateValue}
				/>
				<span>-</span>
				<input
					type="text"
					name="date"
					className="date_input"
					placeholder="дд.мм.гггг"
					value={endDateValue}
					onChange={onChangeEndDateValue}
				/>
			</div>
			<DateRangePicker
				style={pickerStyle}
				showOneCalendar={true}
				format="DD.MM.YYYY"
				locale={locale}
				ranges={[]}
				value={[startDate, endDate]}
				cleanable={false}
				onChange={changeDates}
				toggleComponentClass={'div'}
				onSelect={select}
			/>
		</div>
	);
};

export default CustomDatePicker;
