import React from 'react';
import './style.css';
// import usericon from '../../images/icons/user-icon.png';
import { useSelector } from 'react-redux';
import Profile from '../icons/profile';
import Logout from '../../components/logout';

const NavProfile = () => {
	const profile = useSelector((state) => state.profile);

	return (
		<div>
			<div className="nav_profile">
				<div className="nav_profile__user_icon">
					{/* <img src={usericon} alt="user-icon" /> */}
					<Profile />
				</div>
				<span>{profile.data.name}</span>
				<Logout />
			</div>
		</div>
	);
};

export default NavProfile;
