import React from 'react';

export default function ShopIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 3.125C0.00194546 9.71352 5.37413 15.0543 12 15.0543C18.6259 15.0543 23.9981 9.71352 24 3.125H17.9524C17.9505 6.39186 15.2857 9.04041 12 9.04041C8.71434 9.04041 6.04955 6.39186 6.04765 3.125H0Z"
				fill="#2196F3"
			/>
			<path
				d="M5.92077 21.8754C7.38959 21.8754 8.58031 20.6913 8.58031 19.2307C8.58031 17.77 7.38959 16.5859 5.92077 16.5859C4.45195 16.5859 3.26123 17.77 3.26123 19.2307C3.26123 20.6913 4.45195 21.8754 5.92077 21.8754Z"
				fill="#98CFFA"
			/>
			<path
				d="M18.0799 21.8754C19.5488 21.8754 20.7395 20.6913 20.7395 19.2307C20.7395 17.77 19.5488 16.5859 18.0799 16.5859C16.6111 16.5859 15.4204 17.77 15.4204 19.2307C15.4204 20.6913 16.6111 21.8754 18.0799 21.8754Z"
				fill="#98CFFA"
			/>
		</svg>
	);
}
