import { createSlice } from '@reduxjs/toolkit';

export const adminsSlice = createSlice({
	name: 'admins',
	initialState: {
		data: [],
		sort: {},
		filter: {},
		page: 1,
		sizePerPage: 10,
		totalSize: 0,
	},
	reducers: {
		setDataAdmins: (state, data) => {
			state.data = data.payload;
		},
		setTotalSizeAdmins: (state, totalSize) => {
			state.totalSize = totalSize.payload;
		},
		setPageAdmins: (state, page) => {
			state.page = page.payload;
		},
		setSizePerPageAdmins: (state, sizePerPage) => {
			state.sizePerPage = sizePerPage.payload;
		},
		setSortAdmins: (state, sort) => {
			state.sort = sort.payload;
		},
		setFilterAdmins: (state, filter) => {
			state.filter = filter.payload;
		},
	},
});

export const {
	setDataAdmins,
	setTotalSizeAdmins,
	setPageAdmins,
	setSizePerPageAdmins,
	setSortAdmins,
	setFilterAdmins,
} = adminsSlice.actions;

export default adminsSlice.reducer;
