import React from 'react';
import './style.css';
import CopyIcon from '../../icons/copyIcon';

const CopyLinkInput = (props) => {
	const { label, value } = props;
	const inputRef = React.useRef(null);
	const copy = () => {
		inputRef.current.value = value;
		inputRef.current.select();
		document.execCommand('copy');
		inputRef.current.value = value;
	};
	return (
		<div className="copy_link_input">
			<span className="copy_link_input__label">{label}</span>
			<div className="copy_link_input__form">
				<input
					type="text"
					value={value}
					className="copy_link_input__input"
					ref={inputRef}
					readOnly={true}
				/>
				<CopyIcon onClick={copy} />
			</div>
		</div>
	);
};

export default CopyLinkInput;
