import React from 'react';
import './style.css';
import { translateCodes, codeTypes, MESSAGE_TEMPLATE } from '../../utils/constants';

const CodeInfoApp = (props) => {
	const { app } = props;
	return (
		<div className="info_code">
			<span className="info_code__header">
				Способ доставки кода
			</span>
			<div className="info_code__row border_bottom">
				<span className="info_code__row__left_span">
					Тип
				</span>
				<span className="info_code__row__right">
					{translateCodes[app.code_type]}
				</span>
			</div>
			<div className={app.code_type !== codeTypes.sms ? "info_code__row" : "info_code__row border_bottom"}>
				<span className="info_code__row__left_span">
					Количество символов кода
				</span>
				<span className="info_code__row__right">
					{app.code_length}
				</span>
			</div>
			{
				app.code_type === codeTypes.sms ?
					<div className="info_code__row">
						<span className="info_code__row__left_span">
							Текст сообщения (Пример)
						</span>
							<span className="info_code__row__right">
							{MESSAGE_TEMPLATE.replace('{app}', app.name).replace('{code}', '1234567890'.slice(0, app.code_length))}
						</span>
					</div> : null
			}
		</div>
	);
};

export default CodeInfoApp;
