import React from 'react';
import './style.css';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Popover from '@material-ui/core/Popover';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios';
import { codeTypes, URL_SERVER } from '../../utils/constants';
import { setDataSipTrunks, setDataSmsTrunks } from '../../redux/actions/siptrunks';
import { setSession } from '../../redux/actions/session';
import { useSelector, useDispatch } from 'react-redux';
import ConfirmDeleteConnection from '../modalWindows/confirmDeleteConnection';
import BalanceButton from '../buttons/balance';

const useStyles = makeStyles({
	popover: {
		'& .MuiPopover-paper': {
			padding: 20,
			display: 'flex',
			flexDirection: 'column',
			alignAtems: 'center',
			'& .MuiButtonBase-root': {
				marginTop: 10,
			},
			'& .MuiFormControl-root': {
				marginBottom: 10,
			},
		},
	},
});

const SettingsSip = (props) => {
	const { connection, type } = props;
	const dispatch = useDispatch();
	const connections = useSelector((state) => state.siptrunks);
	const classes = useStyles();
	const settingRef = React.useRef(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [username, setUsername] = React.useState(connection.username);
	const [password, setPassword] = React.useState(connection.secret);
	const [server, setServer] = React.useState(connection.host);
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const handleChange = (e) => {
		(async () => {
			const body = {
				active: e.target.checked,
			};
			await axios
				.patch(`${URL_SERVER}/api/v1/${type === codeTypes.number ? 'siptrunks' : 'smstrunks'}/${connection.id}`, body, {
					withCredentials: true,
				})
				.then((res) => {
					const connect = type === codeTypes.number ? [...connections.dataSip] : [...connections.dataSms];
					const connectToState = connect.map((item) => {
						const newConnect = { ...item };
						if (newConnect.id === res.data.id) {
							newConnect.active = res.data.active;
						}
						return newConnect;
					});
					if (type === codeTypes.number) dispatch(setDataSipTrunks(connectToState));
					else dispatch(setDataSmsTrunks(connectToState));
					setAnchorEl(null);
				})
				.catch((err) => {
					console.log(err.message);
					if (err.message.includes('401')) {
						dispatch(setSession(false));
					}
				});
		})();
	};

	const changePhone = (e) => {
		setUsername(e.target.value);
	};

	const changePassword = (e) => {
		setPassword(e.target.value);
	};

	const changeServer = (e) => {
		setServer(e.target.value);
	};

	const save = () => {
		(async () => {
			const body = {
				username: username,
				secret: password,
				host: server,
			};
			await axios
				.patch(`${URL_SERVER}/api/v1/${type === codeTypes.number ? 'siptrunks' : 'smstrunks'}/${connection.id}`, body, {
					withCredentials: true,
				})
				.then((res) => {
					const connect = type === codeTypes.number ? [...connections.dataSip] : [...connections.dataSms];
					const connectToState = connect.map((item) => {
						const newConnect = { ...item };
						if (newConnect.id === res.data.id) {
							newConnect.username = res.data.username;
							newConnect.secret = res.data.secret;
							newConnect.host = res.data.host;
						}
						return newConnect;
					});
					if (type === codeTypes.number) dispatch(setDataSipTrunks(connectToState));
					else dispatch(setDataSmsTrunks(connectToState));
					setAnchorEl(null);
				})
				.catch((err) => {
					console.log(err.message);
					if (err.message.includes('401')) {
						dispatch(setSession(false));
					}
				});
		})();
	};

	const edit = () => {
		setAnchorEl(settingRef.current);
	};

	const deleteConnection = () => {
		setOpenDeleteModal(true);
	};

	React.useEffect(() => {
		if (!connection.active) {
			settingRef.current.classList.add('settings_disable');
		} else {
			settingRef.current.classList.remove('settings_disable');
		}
	}, [connection.active]);

	return (
		<div className="settings_sip" ref={settingRef}>
			<div className="settings_sip__content">
				<span>{connection.username}</span>
				<span>{connection.host}</span>
				<span className="settings_sip__status">
					{connection.status === 0
						? 'отключен'
						: connection.status === 1
						? 'зарегистрирован'
						: connection.status === 2
						? 'ошибка регистрации'
						: connection.status === 3
						? 'регистрируется'
						: ''}
				</span>
				<div className="settings_sip__actions">
					<BalanceButton
						phone={connection.username}
						password={connection.secret}
						server={connection.host}
						type={type}
					/>
					<Switch
						checked={connection.active}
						onChange={handleChange}
						color="primary"
						name="checked"
						inputProps={{ 'aria-label': 'primary checkbox' }}
					/>
					<EditIcon className="settings_sip__edit_icon" onClick={edit} />
					<DeleteForeverIcon
						className="settings_sip__delete_icon"
						onClick={deleteConnection}
					/>
				</div>
			</div>
			<Popover
				className={classes.popover}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<FormControl variant="outlined">
					<TextField
						id="outlined-adornment-phone"
						label="Логин"
						onChange={changePhone}
						value={username}
					/>
				</FormControl>
				<FormControl variant="outlined">
					<TextField
						label="Пароль"
						onChange={changePassword}
						value={password}
					/>
				</FormControl>
				<FormControl variant="outlined">
					<TextField label="Сервер" onChange={changeServer} value={server} />
				</FormControl>
				<Button variant="outlined" color="primary" onClick={save}>
					Сохранить
				</Button>
			</Popover>
			<ConfirmDeleteConnection
				open={openDeleteModal}
				setOpen={setOpenDeleteModal}
				state={connection}
				type={type}
			/>
		</div>
	);
};

export default SettingsSip;
