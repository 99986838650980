import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
	name: 'session',
	initialState: {
		authorized: true,
		showBalanceAlert: false,
	},
	reducers: {
		setSession: (state, data) => {
			state.authorized = !!data.payload;
		},
		setShowBalanceAlert: (state, data) => {
			state.showBalanceAlert = !!data.payload;
		},
	},
});

export const { setSession, setShowBalanceAlert } = sessionSlice.actions;

export default sessionSlice.reducer;
