import React from 'react';
import { useDispatch } from 'react-redux';
import './style.css';
import CustomButtonWithPlus from '../buttons/customButtonWithPlus';
import ApplicationsCard from '../cards/applicationsCard';
import { setAddAppModal } from '../../redux/actions/modals';
import { USE_SMS } from '../../utils/constants';

const AppsPage = () => {
	const dispatch = useDispatch();

	const showModal = () => {
		dispatch(
			setAddAppModal({
				show: true,
			})
		);
	};

	return (
		<div className="apps_page">
			<h2>Добро пожаловать!</h2>
			<p>
				{`Авторизация по телефону - программное решение для идентификации пользователей по последним цифрам входящего номера телефона${USE_SMS ? ', SMS или озвученному коду при поступлении звонка' : ' или озвученному коду при поступлении звонка'}.
          Верификация пользователя выполняется по последним цифрам входящего звонка${USE_SMS ? ', SMS или озвученному коду при поступлении звонка' : ' или озвученному коду при поступлении звонка'}. Длина кодовой комбинации настраивается индивидуально. `}
			</p>
			<p>
				Для использования приложения Авторизация по телефону необходимо приобрести
				лицензионный ключ.
			</p>

			<div className="apps_page__applications_header">
				<h2>Приложения</h2>
				<CustomButtonWithPlus text="Добавить" onClick={showModal} />
			</div>
			<ApplicationsCard />
		</div>
	);
};

export default AppsPage;
