import React from 'react';
import './style.css';
import CustomInput from '../../inputs/customInput';
import { activateLicense } from '../../../api/license';
import { codeTypes } from '../../../utils/constants';
import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CencelHeaderButton from '../../buttons/cancelHeaderButton';
import CustomButtonHeader from '../../buttons/customButtonHeader';

const BalanceModal = (props) => {
	const { opened, open, setClose, type } = props;
	const [licenseValue, setLicenseValue] = React.useState('');
	const [licenseValid, setLicenseValid] = React.useState('root');
	const [count, setCount] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [licenseInputShow, setLicenseInputShow] = React.useState(false);
	const [errorMessage, setErrorMesasge] = React.useState('');
	const [disabledActivateButton, setDisabledActivateButton] = React.useState(true);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const closeModal = () => {
		setClose(false);
	};

	const checkValidate = (value) => {
		return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
			value
		);
	};

	const changeInput = (value) => {
		setLicenseValue(value);
		setDisabledActivateButton(true);
		if (value.length && checkValidate(value)) {
			setLicenseValid('success');
			setErrorMesasge('');
			setDisabledActivateButton(false);
		} else if (!value.length) {
			setLicenseValid('error');
			setErrorMesasge('Введите лицензионный ключ');
		} else {
			setLicenseValid('error');
			setErrorMesasge('Некорректный ключ');
		}
	};

	const toggleLicenseInputShow = () => {
		setLicenseInputShow(!licenseInputShow);
	};

	React.useEffect(() => {
		if (opened !== 'license') {
			setLicenseInputShow(true);
		}
	}, [opened]);

	const activate = async () => {
		try {
			setIsLoading(true);
			if (licenseValid === 'success') {
				const res = await activateLicense({
					key: licenseValue,
				});
				if (res.status) {
					toggleLicenseInputShow();
					setCount(res.count);
				} else {
					setLicenseValid('error');
					setErrorMesasge(res.message);
				}
			} else if (licenseValue.length && !checkValidate(licenseValue)) {
				setErrorMesasge('Некорректный ключ');
			} else if (!licenseValue.length) {
				setLicenseValid('error');
				setErrorMesasge('Введите лицензионный ключ');
			}
			setDisabledActivateButton(true);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={closeModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div className="balance_card">
				<div className="balance_card__body">
					<h2>Пополнение счета "Авторизация по телефону"</h2>
					<div className={'balance_card__form'}>
						{licenseInputShow ? (
							<>
										<span className="balance_card__form_label">
											Введите лицензионный ключ:
										</span>
								<CustomInput
									mode={licenseValid}
									placeholder="XXXX-XXXX-XXXX"
									value={licenseValue}
									changeInput={changeInput}
									errorMessage={errorMessage}
								/>
								<div className="balance_card__form_buttons">
									<div className="balance_card__form_buttons_container">
										<CencelHeaderButton
											className="close_balance"
											disabled={isLoading}
											text={'Отмена'}
											onClick={closeModal}
										/>
										<CustomButtonHeader
											className="activate_balance"
											disabled={isLoading}
											text={'Активировать'}
											onClick={activate}
											disabledActivateButton={disabledActivateButton}
										/>
									</div>
								</div>
							</>
						) : (
							<div className={'balance_card__form'}>
								<p className="balance_card__form_success">
									Ключ активирован!
								</p>
								<p className="balance_card__form_count_info">
									На ваш баланс зачислено{' '}
									<span className="count">{`${count} ${type === codeTypes.sms ? 'смс' : 'звонков'}`}</span> единиц.
								</p>
								<div className="balance_card__form_options">
									<button
										className="balance_card__footer_link"
										onClick={toggleLicenseInputShow}
									>
												<span className="balance_card__footer_link">
													Активировать ещё
												</span>
									</button>
									<CencelHeaderButton
										text={'Закрыть'}
										className="close_balance_success"
										onClick={closeModal}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
				<CloseIcon className="close_button" onClick={closeModal} />
			</div>
		</Dialog>
	);
};

export default BalanceModal;
