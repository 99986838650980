import React from 'react';
import AdminsTable from '../tables/adminsTable';

const Admins = () => {
	return (
		<div className="balances_container">
			<h2>Список администраторов</h2>
			<AdminsTable />
		</div>
	);
};

export default Admins;
