import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDeleteAdmin = ({ open, setOpen, callback }) => {
	const handleClose = () => {
		setOpen(false);
	};

	const deleteApp = () => {
		callback();
	};

	return (
		<div className="confirm_dialog">
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				maxWidth="md"
			>
				<DialogTitle>
					Вы действительно хотите удалить пользователя из списка
					администраторов?
				</DialogTitle>
				<DialogActions>
					<Button onClick={handleClose}>Отмена</Button>
					<Button onClick={deleteApp}>Удалить</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ConfirmDeleteAdmin;
