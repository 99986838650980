import React from 'react';
import './style.css';

const SmsInfoApp = (props) => {
	const { app } = props;
	return (
		<div className="info_sms">
			<span className="info_sms__header">
				SMSC аккаунт
			</span>
			<div className="info_sms__row border_bottom">
				<span className="info_sms__row__left_span">
					Логин
				</span>
				<span className="info_sms__row__right">
					{app.sms_login}
				</span>
			</div>
			<div className="info_sms__row">
				<span className="info_sms__row__left_span">
					Пароль
				</span>
				<span className="info_sms__row__right">
					{app.sms_password}
				</span>
			</div>
		</div>
	);
};

export default SmsInfoApp;
