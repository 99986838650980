import React from 'react';

export default function AutocallIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.441 0.5C4.67443 0.501821 0 5.07691 0 10.7202C0 16.3635 4.67443 20.9391 10.441 20.9409V15.79C7.58188 15.7882 5.26373 13.5189 5.26373 10.7202C5.26373 7.922 7.58188 5.6522 10.441 5.65084V0.5Z"
				fill="#4599FF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.5591 4.05957C19.3256 4.06094 24 8.63648 24 14.2798C24 19.9231 19.3256 24.4986 13.5591 24.5V19.3496C16.4182 19.3478 18.7363 17.078 18.7363 14.2798C18.7363 11.4816 16.4182 9.21177 13.5591 9.20995V4.05957Z"
				fill="#8FC1FF"
			/>
		</svg>
	);
}
