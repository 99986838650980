import axios from 'axios';
import { URL_SERVER } from '../utils/constants';
import { setSession } from '../redux/actions/session';
import store from '../redux/store';

export const logoutAuth = async () => {
	await axios
		.get(`${URL_SERVER}/logout`, { withCredentials: true })
		.then(() => store.dispatch(setSession(false)))
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			}
		});
};
