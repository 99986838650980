import React from 'react';
import './style.css';

const CommonInfoApp = (props) => {
	const { client_id, borderBottom, show } = props;
	return (
		<div className={show ? 'common_info_app' : 'common_info_app hidden_info'}>
			<div className="common_info_app__row border_bottom">
				<span className="common_info_app__row__span_left">
					Идентификатор приложения
				</span>
				<span>{client_id}</span>
			</div>
			<div
				className={
					borderBottom
						? 'common_info_app__row border_bottom'
						: 'common_info_app__row'
				}
			>
				<span className="common_info_app__row__span_left">Секретный ключ</span>
				<span>••••••••••••••••••••••••••••••••</span>
			</div>
		</div>
	);
};

export default CommonInfoApp;
