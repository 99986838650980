import React from 'react';
import './style.css';

const CencelHeaderButton = (props) => {
	const { onClick, text, disabled } = props;
	return (
		<div
			className={disabled ? 'close_balance disabled_button' : 'close_balance'}
			onClick={onClick}
		>
			{text}
		</div>
	);
};

export default CencelHeaderButton;
