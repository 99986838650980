import axios from 'axios';
import { URL_SERVER } from '../utils/constants';
import { setSession } from '../redux/actions/session';
import store from '../redux/store';
import { setDataApps } from '../redux/actions/apps';
import { setDataAlert } from '../redux/actions/alert';

export const getApplications = async () => {
	await axios
		.get(`${URL_SERVER}/api/v1/apps`, { withCredentials: true })
		.then((res) => {
			store.dispatch(setDataApps(res.data));
		})
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			}
		});
};

export const toggleActive = async (props) => {
	const { app } = props;
	await axios
		.patch(
			`${URL_SERVER}/api/v1/apps/${app.id}`,
			{ active: !app.active },
			{ withCredentials: true }
		)
		.then(async () => {
			await getApplications();
		})
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			}
		});
};

export const updateApplication = async (props) => {
	const { body, app } = props;
	const stateApps = store.getState().apps;
	await axios
		.patch(`${URL_SERVER}/api/v1/apps/${app.id}`, body, {
			withCredentials: true,
		})
		.then((res) => {
			const apps = [...stateApps.data];
			const appsToState = apps.map((app) => {
				return app.id === res.data.id ? res.data : app;
			});
			store.dispatch(setDataApps(appsToState));
			store.dispatch(
				setDataAlert({
					show: true,
					message: 'Приложение изменено',
				})
			);
		})
		.catch((err) => {
			console.log(err.message);
			if (err.message.includes('401')) {
				store.dispatch(setSession(false));
			}
		});
};

export const deleteApplication = async (props) => {
	const { app } = props;
	const stateApps = store.getState().apps;
	await axios
		.delete(`${URL_SERVER}/api/v1/apps/${app.id}`, {
			withCredentials: true,
		})
		.then(() => {
			const appsToState = [...stateApps.data];
			store.dispatch(
				setDataApps(appsToState.filter((item) => item.id !== app.id))
			);
			store.dispatch(
				setDataAlert({
					show: true,
					message: 'Приложение удалено',
				})
			);
		})
		.catch((err) => {
			console.log(err.message);
			if (err.message.includes('401')) {
				store.dispatch(setSession(false));
			}
		});
};

export const createApplication = async (props) => {
	const { body } = props;
	const stateApps = store.getState().apps;
	const isExistAppName = stateApps.data.find((item) => item.name === body.name);
	if (isExistAppName) {
		store.dispatch(
			setDataAlert({
				show: true,
				message: 'Приложение с таким наименованием уже существует',
			})
		);
	} else {
		await axios
			.post(`${URL_SERVER}/api/v1/apps`, body, {
				withCredentials: true,
			})
			.then((res) => {
				const apps = [...stateApps.data];
				apps.push(res.data);
				store.dispatch(setDataApps(apps));
				store.dispatch(
					setDataAlert({
						show: true,
						message: 'Приложение добавлено',
					})
				);
			})
			.catch((err) => {
				console.log(err.message);
				if (err.message.includes('401')) {
					store.dispatch(setSession(false));
				}
			});
	}
};
