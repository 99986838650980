import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	popover: {
		'& .MuiPopover-paper': {
			padding: '8px 0',
			display: 'flex',
			flexDirection: 'column',
			alignAtems: 'center',
		},
		'& .MuiPaper-elevation8': {
			boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
			border: '1px solid #EFEFF3',
		},
		'& .MuiPaper-rounded': {
			borderRadius: 6,
		},
	},
	popover_menu_item: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 15,
		lineHeight: '22px',
		color: '#273270',
		width: 180,
		height: 40,
		paddingLeft: 24,
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			cursor: 'pointer',
			background: '#F6F6FB',
		},
	},
});

const CustomPopover = (props) => {
	const classes = useStyles();
	const {
		anchorEl,
		open,
		handleClose,
		anchorOrigin,
		transformOrigin,
		ChildComponent,
	} = props;
	return (
		<Popover
			className={classes.popover}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
		>
			<ChildComponent />
		</Popover>
	);
};

export default CustomPopover;
