import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
	name: 'profile',
	initialState: {
		data: {},
		updatedAt: '',
	},
	reducers: {
		setDataProfile: (state, data) => {
			state.data = data.payload;
			state.updatedAt = new Date();
		},
	},
});

export const { setDataProfile } = profileSlice.actions;

export default profileSlice.reducer;
