import React from 'react';
import './style.css';
import LogTable from '../tables/logTable';
import LicenseTable from '../tables/licenseTable';
import { useSelector } from 'react-redux';

const LogPage = () => {
	const statePage = useSelector((state) => state.page);

	return (
		<div className="log_page">
			{statePage.table === 'Журнал' ? <LogTable /> : <LicenseTable />}
		</div>
	);
};

export default LogPage;
