import React from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import axios from 'axios';
import { URL_SERVER } from '../../../utils/constants';
import { setDataAdmins } from '../../../redux/actions/admins';
import { setSession } from '../../../redux/actions/session';
import { useSelector, useDispatch } from 'react-redux';
import AlertMessage from '../../alertMessage';
import ConfirmDeleteAdmin from '../../modalWindows/confirmDeleteAdmin';

const DeleteAdmin = ({ id }) => {
	const dispatch = useDispatch();
	const admins = useSelector((state) => state.admins);
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
	const [alert, setAlert] = React.useState({
		show: false,
		severity: null,
		message: null,
	});
	let alertMessage;

	const onCloseAlertMessage = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert({
			...alert,
			show: false,
		});
		if (alertMessage && alertMessage.current)
			alertMessage.current.style.display = 'none';
	};

	const deleteAdmin = () => {
		(async () => {
			if (admins.data.length === 1) {
				setOpenDeleteModal(false);
				setAlert({
					show: true,
					severity: 'error',
					message: 'В системе один администратор',
				});
			} else {
				await axios
					.patch(
						`${URL_SERVER}/api/admin/v1/users/${id}`,
						{ admin: false },
						{
							withCredentials: true,
						}
					)
					.then((res) => {
						const adminsToState = [...admins.data];
						dispatch(
							setDataAdmins(adminsToState.filter((admin) => admin.id !== id))
						);
						setAlert({
							show: true,
							severity: 'success',
							message: 'Пользователь удален из списока администраторов',
						});
					})
					.catch((err) => {
						console.log(err.message);
						if (err.message.includes('401')) {
							dispatch(setSession(false));
						}
					});
			}
		})();
	};

	return (
		<div>
			<DeleteForeverIcon
				className="settings_sip__delete_icon"
				onClick={() => setOpenDeleteModal(true)}
			/>
			<AlertMessage
				open={alert.show}
				close={onCloseAlertMessage}
				severity={alert.severity}
				message={alert.message}
				refSnackBar={alertMessage}
			/>
			<ConfirmDeleteAdmin
				open={openDeleteModal}
				setOpen={setOpenDeleteModal}
				callback={deleteAdmin}
			/>
		</div>
	);
};

export default DeleteAdmin;
