import * as React from 'react';
import axios from 'axios';
import { URL_SERVER } from '../../../utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { setSession } from '../../../redux/actions/session';
import {
	setDataAdmins,
	setPageAdmins,
	setSizePerPageAdmins,
	setTotalSizeAdmins,
	setSortAdmins,
} from '../../../redux/actions/admins';
import { columnsAdmins } from '../columns';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import AddAdmin from '../../buttons/addAdmin';
import DeleteAdmin from '../../buttons/deleteAdmin';
// import './style.css';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

const AdminsTable = () => {
	const state = useSelector((state) => state.admins);
	const dispatch = useDispatch();

	const classes = useStyles();

	const handleChangePage = (e, newPage) => {
		dispatch(setPageAdmins(newPage + 1));
	};

	const handleChangeRowsPerPage = (e) => {
		dispatch(setSizePerPageAdmins(e.target.value));
	};

	const createSortHandler = (e, field) => {
		const isAsc =
			state.sort.sortField === field && state.sort.sortOrder === 'asc';
		dispatch(
			setSortAdmins({
				sortOrder: isAsc ? 'desc' : 'asc',
				sortField: field,
			})
		);
	};

	const customLabel = ({ from, to, count }) => {
		return `${from}-${to} из ${count}`;
	};

	const createRequest = () => {
		let query = '"admin":true';
		if (
			(state.filter && Object.keys(state.filter).length) ||
			(state.searchOption === 'period' && state.searchStart && state.searchEnd)
		) {
			const filteredFields = Object.keys(state.filter);
			const arrQuery = filteredFields.map((item, i) => {
				return `{"${filteredFields[i]}":{"$regex":"${
					state.filter[filteredFields[i]].filterVal
				}"}}`;
			});
			if (
				state.searchOption === 'period' &&
				state.searchStart &&
				state.searchEnd
			) {
				arrQuery.push(
					`{"$and":[{"date":{"$gt":"${state.searchStart}"}},{"date":{"$lt":"${state.searchEnd}"}}]}`
				);
			}

			query = `"$and":[${arrQuery.join(',')},{"admin":true}]`;
		}
		let sort;
		if (!Object.keys(state.sort).length) {
			sort = '';
		} else {
			sort =
				state.sort.sortOrder === 'asc'
					? `"${state.sort.sortField}": 1`
					: `"${state.sort.sortField}":-1`;
		}
		const limit = state.sizePerPage;
		const skip = (state.page - 1) * state.sizePerPage;
		return { limit, skip, sort, query };
	};

	React.useEffect(() => {
		(async () => {
			const { limit, skip, sort, query } = createRequest(state);
			await axios
				.get(
					`${URL_SERVER}/api/admin/v1/users?limit=${limit}&skip=${skip}&sort={${sort}}&query={${query}}`,
					{ withCredentials: true }
				)
				.then((res) => {
					dispatch(setDataAdmins(res.data));
					dispatch(setTotalSizeAdmins(Number(res.headers['x-total-count'])));
				})
				.catch((err) => {
					if (err.message.includes('401')) {
						console.log(err.message);
						dispatch(setSession(false));
					}
				});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.page, state.sizePerPage, state.sort, state.filter]);

	return (
		<div style={{ height: 700, width: '100%' }}>
			<TableContainer component={Paper}>
				<Table
					className={classes.table}
					size="small"
					aria-label="a dense table"
				>
					<TableHead>
						<TableRow>
							{columnsAdmins.map((column) => (
								<TableCell
									align="center"
									key={column.field}
									padding="normal"
									sortDirection={
										state.sort.sortField === column.field
											? state.sort.sortOrder
											: false
									}
								>
									<TableSortLabel
										active={state.sort.sortField === column.field}
										direction={
											state.sort.sortField === column.field
												? state.sort.sortOrder
												: 'asc'
										}
										onClick={(e) => createSortHandler(e, column.field)}
									>
										{column.headerName}
									</TableSortLabel>
								</TableCell>
							))}
							<TableCell align="center"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{state.data.map((row) => (
							<TableRow key={row.id}>
								<TableCell align="center">{row.name}</TableCell>
								<TableCell align="center">{row.phone}</TableCell>
								<TableCell align="center">
									<DeleteAdmin id={row.id} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 50]}
				component="div"
				count={state.totalSize}
				rowsPerPage={state.sizePerPage}
				page={state.page - 1}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage="На странице:"
				labelDisplayedRows={customLabel}
			/>
			<AddAdmin />
		</div>
	);
};

export default AdminsTable;
