import React from 'react';
import './style.css';
import logo from '../../images/new-logo.png';
import { VERSION_RELEASE } from '../../utils/constants';
import { Link } from 'react-router-dom';

const Logo = () => {
	return (
		<div className="header_logo">
			<Link to="/" className="header_logo_link">
				<img src={logo} alt="logo" />
			</Link>
			<span className="header_logo_version">{VERSION_RELEASE}</span>
		</div>
	);
};

export default Logo;
