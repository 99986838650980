import React from 'react';
import './style.css';
import AccordionGetStarted from '../../accordionGetStarted';
import AccordionApp from '../../accordionApp';
import { useSelector, useDispatch } from 'react-redux';
import { getApplications } from '../../../api/apps';
import { setAppSort } from '../../../redux/actions/apps';
import ArrowSort from '../../icons/arrowSort';

const ApplicationsCard = () => {
	const state = useSelector((state) => state.apps);
	const dispatch = useDispatch();
	const [showGetStarted, setShowGetStarted] = React.useState(false);
	const [sortedData, setSortedDate] = React.useState([]);

	const sortByName = () => {
		dispatch(
			setAppSort({
				sortField: 'name',
				sortOrder:
					state.sort.sortField === 'active' || state.sort.sortOrder === 'asc'
						? 'desc'
						: 'asc',
			})
		);
	};

	const sortByStatus = () => {
		dispatch(
			setAppSort({
				sortField: 'active',
				sortOrder:
					state.sort.sortField === 'name' || state.sort.sortOrder === 'asc'
						? 'desc'
						: 'asc',
			})
		);
	};

	React.useEffect(() => {
		const sortedApps = [...state.data];
		if (state.sort.sortField === 'name') {
			sortedApps.sort((a, b) => {
				if (state.sort.sortOrder === 'asc') {
					if (a.name > b.name) return 1;
					if (a.name === b.name) return 0;
					if (a.name < b.name) return -1;
				} else {
					if (a.name > b.name) return -1;
					if (a.name === b.name) return 0;
					if (a.name < b.name) return 1;
				}
				return false;
			});
		} else if (state.sort.sortField === 'active') {
			sortedApps.sort((a, b) => {
				if (state.sort.sortOrder === 'asc') {
					if (a.active > b.active) return 1;
					if (a.active === b.active) return 0;
					if (a.active < b.active) return -1;
				} else {
					if (a.active > b.active) return -1;
					if (a.active === b.active) return 0;
					if (a.active < b.active) return 1;
				}
				return false;
			});
		}
		setSortedDate(sortedApps);
	}, [state.sort.sortField, state.sort.sortOrder, dispatch, state.data]);

	React.useEffect(() => {
		setShowGetStarted(!Boolean(state.data.length));
	}, [state.data]);

	React.useEffect(() => {
		getApplications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="applications_card">
			<div className="applications_card__header">
				<div className="applications_card__header__name" onClick={sortByName}>
					<span>Название</span>
					{state.sort.sortField === 'name' ? (
						<ArrowSort
							direction={state.sort.sortOrder === 'asc' ? 'asc' : 'desc'}
						/>
					) : null}
				</div>
				<div
					className="applications_card__header__name applications_card__header__margin_span"
					onClick={sortByStatus}
				>
					<span>Статус</span>
					{state.sort.sortField === 'active' ? (
						<ArrowSort
							direction={state.sort.sortOrder === 'asc' ? 'asc' : 'desc'}
						/>
					) : null}
				</div>
				<span>{}</span>
			</div>
			<AccordionGetStarted
				expanded={showGetStarted}
				change={setShowGetStarted}
			/>
			{sortedData.map((app) => (
				<AccordionApp app={app} key={app.id} />
			))}
		</div>
	);
};

export default ApplicationsCard;
