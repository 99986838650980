import { createSlice } from '@reduxjs/toolkit';

export const appsSlice = createSlice({
	name: 'profile',
	initialState: {
		data: [],
		selected: 'all',
		sort: {
			sortOrder: 'asc',
			sortField: 'name',
		},
	},
	reducers: {
		setDataApps: (state, data) => {
			state.data = data.payload;
		},
		setDataSelected: (state, data) => {
			state.selected = data.payload;
		},
		setAppSort: (state, data) => {
			state.sort = data.payload;
		},
	},
});

export const {
	setDataApps,
	setDataSelected,
	setAppSort
} = appsSlice.actions;

export default appsSlice.reducer;
