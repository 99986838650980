import axios from 'axios';
import { URL_SERVER } from '../utils/constants';
import store from '../redux/store';
import { setSession } from '../redux/actions/session';
import { setDataSipTrunks, setDataSmsTrunks } from '../redux/actions/siptrunks';

export const getSipTruncks = async () => {
	await axios
		.get(`${URL_SERVER}/api/v1/siptrunks`, { withCredentials: true })
		.then((res) => {
			store.dispatch(setDataSipTrunks(res.data));
		})
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			}
		});
};

export const getSmsTruncks = async () => {
	await axios
		.get(`${URL_SERVER}/api/v1/smstrunks`, { withCredentials: true })
		.then((res) => {
			store.dispatch(setDataSmsTrunks(res.data));
		})
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			}
		});
};