import { createSlice } from '@reduxjs/toolkit';

const newStartDate = `${new Date().getFullYear()}-${
	new Date().getMonth() + 1 < 10
		? `0${new Date().getMonth() + 1}`
		: new Date().getMonth() + 1
}-${
	new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()
} 00:00:00`;

const newEndDate = `${new Date().getFullYear()}-${
	new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1
}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()} 23:59:59`;

export const callsSlice = createSlice({
	name: 'profile',
	initialState: {
		data: [],
		sort: {
			sortOrder: 'desc',
			sortField: 'createAt',
		},
		filter: {},
		searchStart: newStartDate,
		searchEnd: newEndDate,
		searchOption: 'period',
		page: 1,
		sizePerPage: Math.round((window.innerHeight - 364 - 120) / 56) + 3,
		totalSize: 0,
	},
	reducers: {
		setDataCalls: (state, data) => {
			state.data = data.payload;
		},
		setTotalSizeCalls: (state, totalSize) => {
			state.totalSize = totalSize.payload;
		},
		setPageCalls: (state, page) => {
			state.page = page.payload;
		},
		setSizePerPageCalls: (state, sizePerPage) => {
			state.sizePerPage = sizePerPage.payload;
		},
		setSortCalls: (state, sort) => {
			state.sort = sort.payload;
		},
		setFilterCalls: (state, filter) => {
			state.filter = filter.payload;
		},
		setSearchStartCalls: (state, searchStart) => {
			state.searchStart = searchStart.payload;
		},
		setSearchEndCalls: (state, searchEnd) => {
			state.searchEnd = searchEnd.payload;
		},
		setSearchOptionCalls: (state, searchOption) => {
			state.searchOption = searchOption.payload;
		},
	},
});

export const {
	setDataCalls,
	setTotalSizeCalls,
	setPageCalls,
	setSizePerPageCalls,
	setSortCalls,
	setFilterCalls,
	setSearchStartCalls,
	setSearchEndCalls,
	setSearchOptionCalls,
} = callsSlice.actions;

export default callsSlice.reducer;
