import axios from 'axios';
import { URL_SERVER } from '../utils/constants';
import {
	setDataBalances,
	setTotalSizeBalances,
} from '../redux/actions/balances';
import { setSession } from '../redux/actions/session';
import store from '../redux/store';
import { getProfile } from './users';

const createRequest = (state) => {
	let query = '';
	if (
		(state.filter && Object.keys(state.filter).length) ||
		(state.searchOption === 'period' && state.searchStart && state.searchEnd)
	) {
		const filteredFields = Object.keys(state.filter);
		const arrQuery = filteredFields.map((item, i) => {
			return `{"${filteredFields[i]}":{"$regex":"${
				state.filter[filteredFields[i]].filterVal
			}"}}`;
		});
		if (
			state.searchOption === 'period' &&
			state.searchStart &&
			state.searchEnd
		) {
			arrQuery.push(
				`{"$and":[{"date":{"$gt":"${state.searchStart}"}},{"date":{"$lt":"${state.searchEnd}"}}]}`
			);
		}

		query = `"$and":[${arrQuery.join(',')}]`;
	}
	let sort;
	if (!Object.keys(state.sort).length) {
		sort = '';
	} else {
		sort =
			state.sort.sortOrder === 'asc'
				? `"${state.sort.sortField}": 1`
				: `"${state.sort.sortField}":-1`;
	}
	const limit = state.sizePerPage;
	const skip = (state.page - 1) * state.sizePerPage;
	return { limit, skip, sort, query };
};

export const getBalances = async () => {
	const stateLicense = store.getState().balances;
	const { limit, skip, sort, query } = createRequest(stateLicense);
	await axios
		.get(
			`${URL_SERVER}/api/v1/balances?limit=${limit}&skip=${skip}&sort={${sort}}&query={${query}}`,
			{ withCredentials: true }
		)
		.then((res) => {
			store.dispatch(setDataBalances(res.data));
			store.dispatch(
				setTotalSizeBalances(Number(res.headers['x-total-count']))
			);
		})
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			}
		});
};

export const getMoreBalances = async () => {
	const stateLicense = store.getState().balances;
	const { limit, skip, sort, query } = createRequest(stateLicense);
	await axios
		.get(
			`${URL_SERVER}/api/v1/balances?limit=${limit}&skip=${skip}&sort={${sort}}&query={${query}}`,
			{ withCredentials: true }
		)
		.then((res) => {
			let stateToStore = [...stateLicense.data];
			const stateArrayId = stateToStore.map((item) => item.id);
			const newItemArray = [];
			res.data.map((item) => {
				if (!stateArrayId.includes(item.id)) {
					newItemArray.push(item);
				}
				return null;
			});
			stateToStore = stateToStore.concat(newItemArray);
			store.dispatch(setDataBalances(stateToStore));
			store.dispatch(
				setTotalSizeBalances(Number(res.headers['x-total-count']))
			);
		})
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			}
		});
};

export const activateLicense = async (props) => {
	const { key } = props;
	return new Promise((resolve, _) => {
		axios
			.get(`${URL_SERVER}/balance/addCalls?key=${key}`, {
				withCredentials: true,
			})
			.then(async (res) => {
				if (res.data.success) {
					await getProfile();
					// store.dispatch(
					// 	setDataAlert({
					// 		show: true,
					// 		message: 'Лицензия активирована',
					// 	})
					// );
					resolve({
						status: true,
						count: res.data.count,
					});
				} else {
					if (res.data.message.includes('invalid')) {
						resolve({
							status: false,
							message: 'Невалидный лицензионный ключ',
						});
						// store.dispatch(
						// 	setDataAlert({
						// 		show: true,
						// 		message: 'Невалидный лицензионный ключ',
						// 	})
						// );
					} else if (res.data.message.includes('already activated')) {
						resolve({
							status: false,
							message: 'Лицензионный ключ уже был активирован',
						});
						// store.dispatch(
						// 	setDataAlert({
						// 		show: true,
						// 		message: 'Лицензионный ключ уже был активирован',
						// 	})
						// );
					} else if (res.data.message.includes('Invalid license type')) {
						resolve({
							status: false,
							message: 'Не правильный тип лицензионного ключа',
						});
						// store.dispatch(
						// 	setDataAlert({
						// 		show: true,
						// 		message: 'Не правильный тип лицензионного ключа',
						// 	})
						// );
					} else {
						resolve({
							status: false,
						});
						// store.dispatch(
						// 	setDataAlert({
						// 		show: true,
						// 		message: res.data.message,
						// 	})
						// );
					}
				}
			})
			.catch((err) => {
				// store.dispatch(
				// 	setDataAlert({
				// 		show: true,
				// 		message: 'Ошибка при активации лицензии',
				// 	})
				// );
				resolve({ status: false, message: 'Ошибка при активации лицензии' });
			});
	});
};
