import React from 'react';
import './style.css';
import CommonInfoApp from '../commonInfoApp';
import CopyLinkInput from '../inputs/copyLinkInput';
import { URL_SERVER } from '../../utils/constants';

const InfoAuthOAUTH2 = (props) => {
	const { app } = props;
	const addressAuthWidget = `${URL_SERVER}/authorize/?redirect_uri=${app.redirect_uri}&client_id=${app.client_id}`;

	return (
		<div className="info_auth_oauth2">
			<span className="info_auth_oauth2__header">Авторизация OAUTH 2</span>
			<CommonInfoApp
				client_id={app.client_id}
				borderBottom={true}
				show={app.auth_type === 2 ? true : false}
			/>
			<div className="info_auth_oauth2__row_text">
				<span className="info_auth_oauth2__left_span">
					Адрес авторизации в приложении
				</span>
				<span className="info_auth_oauth2__right_span">{app.redirect_uri}</span>
			</div>
			<div className="info_auth_oauth2__row_text">
				<span className="info_auth_oauth2__left_span">
					Адрес получения токена
				</span>
				<span className="info_auth_oauth2__right_span">
					{`${URL_SERVER}/api/v1/token`}
				</span>
			</div>
			<div className="info_auth_oauth2__row_text">
				<span className="info_auth_oauth2__left_span">
					Адрес получения профиля
				</span>
				<span className="info_auth_oauth2__right_span">
					{`${URL_SERVER}/api/v1/profile`}
				</span>
			</div>
			<div className="info_auth_oauth2__row">
				<span className="info_auth_oauth2__left_span margin_top">
					Адрес страницы авторизации
				</span>
				<CopyLinkInput
					label="Добавьте ссылку на свой ресурс для перехода на страницу авторизации"
					value={addressAuthWidget}
				/>
			</div>
		</div>
	);
};

export default InfoAuthOAUTH2;
