import React from 'react';

export default function Profile() {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="20" cy="20" r="20" fill="#ECEDF0" />
			<ellipse
				cx="19.9394"
				cy="16.8182"
				rx="3.81821"
				ry="3.81818"
				stroke="#333333"
				strokeWidth="1.2"
				strokeLinecap="square"
			/>
			<path
				d="M25.8788 26.9999C25.8788 26.22 25.7252 25.4476 25.4267 24.727C25.1282 24.0064 24.6907 23.3517 24.1392 22.8002C23.5877 22.2486 22.9329 21.8111 22.2123 21.5127C21.4917 21.2142 20.7194 21.0605 19.9394 21.0605C19.1594 21.0605 18.3871 21.2142 17.6665 21.5127C16.9459 21.8111 16.2911 22.2486 15.7396 22.8002C15.1881 23.3517 14.7506 24.0064 14.4521 24.727C14.1536 25.4476 14 26.22 14 26.9999"
				stroke="#333333"
				strokeWidth="1.2"
			/>
		</svg>
	);
}
