import React from 'react';
import './style.css';

const SidebarMenuButton = (props) => {
	const { text, onClick, page } = props;
	return (
		<button
			className={
				page === text
					? 'sidebar_menu_button sidebar_menu_button__active'
					: 'sidebar_menu_button'
			}
			onClick={() => onClick(text)}
		>
			{text}
		</button>
	);
};

export default SidebarMenuButton;
