import { createSlice } from '@reduxjs/toolkit';

export const balancesSlice = createSlice({
	name: 'profile',
	initialState: {
		data: [],
		sort: {
			sortOrder: 'desc',
			sortField: 'createAt',
		},
		filter: {},
		page: 1,
		sizePerPage: Math.round((window.innerHeight - 283 - 120) / 56) + 3,
		totalSize: 0,
	},
	reducers: {
		setDataBalances: (state, data) => {
			state.data = data.payload;
		},
		setTotalSizeBalances: (state, totalSize) => {
			state.totalSize = totalSize.payload;
		},
		setPageBalances: (state, page) => {
			state.page = page.payload;
		},
		setSizePerPageBalances: (state, sizePerPage) => {
			state.sizePerPage = sizePerPage.payload;
		},
		setSortBalances: (state, sort) => {
			state.sort = sort.payload;
		},
		setFilterBalances: (state, filter) => {
			state.filter = filter.payload;
		},
	},
});

export const {
	setDataBalances,
	setTotalSizeBalances,
	setPageBalances,
	setSizePerPageBalances,
	setSortBalances,
	setFilterBalances,
} = balancesSlice.actions;

export default balancesSlice.reducer;
